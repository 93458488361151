export const outsideLinks = {
  base: {
    en: 'https://pps.aviationexam.com/store',
    de: 'https://pps.aviationexam.com/store/de',
  },
  freeTest: {
    en: 'https://pps.aviationexam.com/app/free-test',
    de: 'https://pps.aviationexam.com/app/free-test',
  },
  easa: {
    en: '/products/20/easa-in-english.aspx',
    de: '/produkte/easa-auf-deutsch',
  },
  easaSubscription: {
    en: '/product/21/all-subjects-of-easa-syllabus.aspx',
    de: '/produkte/easa-auf-deutsch/alle-facher-des-easa-syllabus',
  },
  ppl: {
    en: '/produkte/german-specific/ppl-questionnaire',
    de: '/produkte/german-specific/ppl-fragenkatalog',
  },
  splUl: {
    de: '/produkte/german-specific/ul-spl-ll-fragenkatalog',
  },
  ebooks: {
    en: '/products/20/easa-in-english.aspx/question-ebooks',
    de: '/produkte/easa-auf-deutsch/ebooks',
  },
  etextbooks: {
    en: '/products/20/easa-in-english.aspx/electronic-textbooks',
  },
  ebook010: {
    en: '/product/easa-in-english/010-air-law-ebook',
    de: '/produkte/easa-auf-deutsch/010-luftrecht-ebook',
  },
  ebook021: {
    en: '/product/easa-in-english/021-airframe-systems-electrics-power-plant-ebook',
    de: '/produkte/easa-auf-deutsch/021-luftfahrzeugzelle-systeme-triebwerke-elektrik-ebook',
  },
  ebook022: {
    en: '/product/easa-in-english/022-instrumentation-ebook',
    de: '/produkte/easa-auf-deutsch/022-instrumente-ebook',
  },
  ebook031: {
    en: '/product/easa-in-english/031-mass-and-balance-ebook',
    de: '/produkte/easa-auf-deutsch/031-masse-schwerpunktlage-ebook',
  },
  ebook032: {
    en: '/product/easa-in-english/032-034-performance-ebook',
    de: '/produkte/easa-auf-deutsch/032-034-flugleistung-ebook',
  },
  ebook033: {
    en: '/product/easa-in-english/033-flight-planning-and-monitoring-ebook',
    de: '/produkte/easa-auf-deutsch/033-flugplanung-fluguberwachung-ebook',
  },
  ebook040: {
    en: '/product/easa-in-english/040-human-performance-and-limitations-ebook',
    de: '/produkte/easa-auf-deutsch/040-menschliches-leistungsvermogen-ebook',
  },
  ebook050: {
    en: '/product/easa-in-english/050-meteorology-ebook',
    de: '/produkte/easa-auf-deutsch/050-meteorologie-ebook',
  },
  ebook061: {
    en: '/product/easa-in-english/061-general-navigation-ebook',
    de: '/produkte/easa-auf-deutsch/061-allgemeine-navigation-ebook',
  },
  ebook062: {
    en: '/product/easa-in-english/062-radio-navigation-ebook',
    de: '/produkte/easa-auf-deutsch/062-funknavigation-ebook',
  },
  ebook070: {
    en: '/product/easa-in-english/070-operational-procedures-ebook',
    de: '/produkte/easa-auf-deutsch/070-betriebliche-verfahren-ebook',
  },
  ebook081: {
    en: '/product/easa-in-english/081-082-principles-of-flight-ebook',
    de: '/produkte/easa-auf-deutsch/081-082-aerodynamik-ebook',
  },
  ebook092: {
    en: '/product/easa-in-english/091-092-vfr-ifr-communications-ebook',
    de: '/produkte/easa-auf-deutsch/091-092-vfr-ifr-kommunikation-ebook',
  },
  ebookComplete: {
    en: '/product/easa-in-english/set-of-13-easa-ebooks',
    de: '/produkte/easa-auf-deutsch/alle-easa-ebook',
  },
  etextbook010: {
    en: '/product/easa-in-english/textbooks/010-air-law-textbook',
  },
  etextbook021: {
    en: '/product/easa-in-english/textbooks/021-airframe-systems-electrics-power-plant-textbook',
  },
  etextbook022: {
    en: '/product/easa-in-english/textbooks/022-instrumentation-textbook',
  },
  etextbook031: {
    en: '/product/easa-in-english/textbooks/031-mass-and-balance-textbook',
  },
  etextbook032: {
    en: '/product/easa-in-english/textbooks/032-performance-textbook',
  },
  etextbook033: {
    en: '/product/easa-in-english/textbooks/033-flight-planning-and-monitoring-textbook',
  },
  etextbook040: {
    en: '/product/easa-in-english/textbooks/040-human-performance-and-limitations-textbook',
  },
  etextbook050: {
    en: '/product/easa-in-english/textbooks/050-meteorology-textbook',
  },
  etextbook061: {
    en: '/product/easa-in-english/textbooks/061-general-navigation-textbook',
  },
  etextbook062: {
    en: '/product/easa-in-english/textbooks/062-radio-navigation-textbook',
  },
  etextbook070: {
    en: '/product/easa-in-english/textbooks/070-operational-procedures-textbook',
  },
  etextbook081: {
    en: '/product/easa-in-english/textbooks/081-principles-of-flight-textbook',
  },
  etextbook090: {
    en: '/product/easa-in-english/textbooks/090-communications-textbook',
  },
};
