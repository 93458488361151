import React from 'react';
import Default from './Default';
import Filled from './Filled';

export interface SyncIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'default' | 'filled';
  size?: number;
}

function SyncIcon({ variant = 'default', size, ...props }: SyncIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    case 'filled':
      return <Filled {...dimensions} {...props} />;
    default:
      return <Default {...dimensions} {...props} />;
  }
}

export default React.memo(SyncIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
