import * as React from 'react';
import { SVGProps } from 'react';

function SvgFolder(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 0C1.355 0 0 1.355 0 3v36c0 1.645 1.355 3 3 3h40c1.645 0 3-1.355 3-3V7c0-1.645-1.355-3-3-3H16c.087 0-.031 0-.275-.281-.245-.282-.546-.75-.86-1.25-.314-.501-.643-1.036-1.058-1.506C13.392.493 12.819 0 12 0H3Zm0 2h9c-.06 0 .061.007.309.287.247.28.548.745.86 1.244.314.5.637 1.032 1.044 1.5C14.619 5.5 15.179 6 16 6h27c.565 0 1 .435 1 1v2.188A2.936 2.936 0 0 0 43 9H3c-.352 0-.685.074-1 .188V3c0-.565.435-1 1-1Zm0 9h40c.565 0 1 .435 1 1v27c0 .565-.435 1-1 1H3c-.565 0-1-.435-1-1V12c0-.565.435-1 1-1Z"
        fill="#FF5236"
      />
    </svg>
  );
}

export default SvgFolder;
