import { createStyles, keyframes } from '@aviationexam/core';

export const float = keyframes({
  '0%': {
    transform: 'translateY(0px) translateX(0px)',
  },
  '25%': {
    transform: 'translateY(-30px) translateX(30px)',
  },
  '50%': {
    transform: 'translateY(0px) translateX(30px)',
  },
  '75%': {
    transform: 'translateY(-30px) translateX(0px)',
  },
  '100%': {
    transform: 'translateY(0px) translateX(0px)',
  },
});

export const reverseFloat = keyframes({
  '0%': {
    transform: 'translateY(0px) translateX(0px)',
  },
  '25%': {
    transform: 'translateY(30px) translateX(-30px)',
  },
  '50%': {
    transform: 'translateY(0px) translateX(-30px)',
  },
  '75%': {
    transform: 'translateY(30px) translateX(0px)',
  },
  '100%': {
    transform: 'translateY(0px) translateX(0px)',
  },
});

export default createStyles((_, { reverse }: { reverse: boolean }) => ({
  root: {
    position: 'absolute',
    zIndex: 0,
    animation: `${reverse ? reverseFloat : float} 12s ease-in-out infinite`,
  },
}));
