import { createStyles } from '@aviationexam/core';

function getBackdropPosition(backdrop: 'left' | 'center' | 'right') {
  switch (backdrop) {
    case 'left':
      return { left: -30 };
    case 'right':
      return { left: 30 };
    default:
      return { left: 0 };
  }
}

interface MemojiStyles {
  backdrop: 'left' | 'center' | 'right';
  color: string;
}

export default createStyles((theme, { backdrop, color }: MemojiStyles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.xl,
  },
  imageWrapper: {
    position: 'relative',
    width: 100,
    height: 100,
  },
  imageContainer: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 9999,
    backgroundColor: theme.white,
    boxShadow: theme.shadows.under,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  backdrop: {
    position: 'absolute',
    width: 100,
    height: 100,
    borderRadius: 9999,
    backgroundColor: color,
    zIndex: 0,
    top: 20,
    ...getBackdropPosition(backdrop),
  },
  image: {
    zIndex: 1,
    maxWidth: '130%',
  },
  hiddenImage: {
    display: 'none',
  },
}));
