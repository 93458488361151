import {
  PublicCommonClient,
  PublicSupportMessageClient,
  PublicQuestionClient,
  PublicUserNotificationClient,
} from '@aviationexam/api-client-public';
import { AdminCommonDataBlogClient } from '@aviationexam/api-client-admin';
import { StudentUserClient } from '@aviationexam/api-client-student';

export const adminBlogClient = new AdminCommonDataBlogClient('/api');
export const publicQuestionClient = new PublicQuestionClient('/api');
export const publicSupportMessageClient = new PublicSupportMessageClient('/api');
export const publicUserNotificationClient = new PublicUserNotificationClient('/api');
export const publicCommonClient = new PublicCommonClient('/api');
export const studentUserClient = new StudentUserClient('/api');
