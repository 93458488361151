export function scrollIntoView(idOrElement: string | HTMLElement | undefined): void {
  if (typeof document === 'undefined' || typeof window === 'undefined') {
    return;
  }
  if (typeof idOrElement === 'string') {
    const element = document.getElementById(idOrElement);
    window.scroll({ top: element?.offsetTop, behavior: 'smooth' });
  } else {
    window.scroll({ top: idOrElement?.offsetTop, behavior: 'smooth' });
  }
}
