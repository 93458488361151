import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 46"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 28c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm7 13c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1 1-.4 1-1Zm1-7c-.6 0-1-.4-1-1V13c0-.6.4-1 1-1h20c.6 0 1 .4 1 1v12l2 .7V13c0-1.6-1.4-3-3-3H13c-1.6 0-3 1.4-3 3v20c0 1.6 1.4 3 3 3h11.1l-.9-2H13Zm5 7c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1 1-.4 1-1Zm-3-3h-2c0 .6.4 1 1 1s1-.4 1-1Zm-8 0c0 .6.4 1 1 1s1-.4 1-1-.4-1-1-1-1 .4-1 1Zm1-7c-.6 0-1 .4-1 1s.4 1 1 1v-2Zm-3 3c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm0 6c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm15-1v4c-.6 0-1 .4-1 1h-4c0-.6-.4-1-1-1s-1 .4-1 1H9c0-.6-.4-1-1-1s-1 .4-1 1H3c0-.6-.4-1-1-1v-4c.6 0 1-.4 1-1s-.4-1-1-1v-4c.6 0 1-.4 1-1s-.4-1-1-1v-4c.6 0 1-.4 1-1h4c0 .6.4 1 1 1v-3H3c-1.6 0-3 1.4-3 3v16c0 1.6 1.4 3 3 3h16c1.6 0 3-1.4 3-3v-5h-3c0 .6.4 1 1 1Zm21-21c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1Zm2-18H27c-1.6 0-3 1.4-3 3v5h3c0-.6-.4-1-1-1V3c.6 0 1-.4 1-1h4c0 .6.4 1 1 1s1-.4 1-1h4c0 .6.4 1 1 1s1-.4 1-1h4c0 .6.4 1 1 1v4c-.6 0-1 .4-1 1s.4 1 1 1v4c-.6 0-1 .4-1 1s.4 1 1 1v4c-.6 0-1 .4-1 1h-4c0-.6-.4-1-1-1v3h5c1.6 0 3-1.4 3-3V3c0-1.6-1.4-3-3-3Zm-5 15c.6 0 1-.4 1-1s-.4-1-1-1v2Zm3-3c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1ZM30 5c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1 1-.4 1-1Zm11 1c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1Zm-9 1c-.6 0-1 .4-1 1h2c0-.6-.4-1-1-1Zm6 0c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1Zm-2-2c0-.6-.4-1-1-1s-1 .4-1 1 .4 1 1 1 1-.4 1-1Zm2.6 29.6 3.9-2.8c.3-.2.5-.6.4-1-.1-.4-.3-.7-.7-.8l-20-7H22c-.3 0-.6.2-.8.4-.2.3-.2.7-.1 1l9 20c.2.4.5.6.9.6s.7-.2.9-.6l2.4-4.9 6 5.2c.2.2.4.2.7.2h.1c.3 0 .5-.1.7-.4l3.1-3.7c.2-.2.3-.5.2-.7-.1-.2-.2-.5-.4-.7l-6.1-4.8Zm2.2 8-6.2-5.3c-.2-.3-.5-.3-.8-.3-.3.1-.6.3-.7.5L31 41.7l-7.2-16 16 5.6-3.4 2.4c-.3.2-.4.5-.4.8 0 .3.1.6.4.8l6.3 5.2-1.9 2.1Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
