import * as React from 'react';
import { SVGProps } from 'react';

function SvgUk(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16Z"
        fill="#012169"
      />
      <path
        d="M0 0v3.575L28.422 32H32v-3.575L3.578 0H0Zm32 0v3.575L3.578 32H0v-3.575L28.422 0H32Z"
        fill="#fff"
      />
      <path d="M13.334 0v32h5.332V0h-5.332ZM0 10.669V21.33h32V10.67H0Z" fill="#fff" />
      <path
        d="M0 12.8v6.4h32v-6.4H0ZM14.4 0v32h3.2V0h-3.2ZM0 32l10.666-10.669h2.387L2.384 32H0ZM0 0l10.666 10.669H8.28L0 2.388V0Zm18.95 10.669L29.616 0H32L21.334 10.669H18.95ZM32 32 21.334 21.331h2.385L32 29.613V32Z"
        fill="#C8102E"
      />
    </svg>
  );
}

export default SvgUk;
