import React from 'react';
import { usePublicTranslation } from 'features/localization';
import Animated from '../../images/memojis/krystof_animated.gif';
import Static from '../../images/memojis/krystof_static.gif';
import { Memoji } from './Memoji';

export function KrystofMemoji() {
  const { t } = usePublicTranslation();
  return (
    <Memoji
      src={Static}
      animatedSrc={Animated}
      name={t('team.krystof.name')}
      title={t('team.krystof.title')}
      color="rgba(0, 102, 204, 0.15)"
      backdrop="left"
    />
  );
}
