import { createStyles } from '@aviationexam/core';

export const HEADER_HEIGHT = 65;

export default createStyles(() => ({
  root: {
    // overflowX: 'hidden',
  },
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: HEADER_HEIGHT,
    zIndex: 50,
  },
  main: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#FBFAFB', // '#fafbfc',
    marginTop: HEADER_HEIGHT,
    height: 'auto',
  },
}));
