module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/azp/_work/35/s/node_modules/gatsby-remark-relative-images","id":"f17158a1-adcb-54a3-b83b-e4cc8444b729","name":"gatsby-remark-relative-images","version":"2.0.5","modulePath":"/azp/_work/35/s/node_modules/gatsby-remark-relative-images/dist/index.js","module":{"defaultPluginOptions":{"staticFolderName":"static","include":[],"exclude":[]}},"pluginOptions":{"plugins":[],"staticFolderName":"static"},"nodeAPIs":["onCreateNode"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/azp/_work/35/s/node_modules/gatsby-remark-images","id":"926abd48-ce1b-519c-90cd-948bc4e44982","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/azp/_work/35/s/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1300},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/azp/_work/35/s","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1300},
    },{
      plugin: require('../plugins/gatsby-plugin-analytics/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDFQSQK","includeInDevelopment":true,"defaultDataLayer":{}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
