import * as React from 'react';
import { SVGProps } from 'react';

function SvgAudio(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 51"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 .969c-6.062 0-11.03 4.804-11.03 10.75V24.28c0 5.946 4.968 10.75 11.03 10.75 6.063 0 11.032-4.804 11.032-10.75V11.72c0-5.946-4.97-10.75-11.032-10.75Zm0 2.062c4.985 0 8.97 3.883 8.97 8.688V12h-5.563c-.551.027-.98.496-.954 1.047.028.55.497.98 1.047.953h5.47v2h-5.563c-.551.027-.98.496-.954 1.047.028.55.497.98 1.047.953h5.47v2h-5.563c-.551.027-.98.496-.954 1.047.028.55.497.98 1.047.953h5.47v2.281c0 4.805-3.985 8.688-8.97 8.688-4.984 0-8.968-3.883-8.968-8.688V22H12.5c.36.004.696-.184.88-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.88-.496H7.032v-2H12.5c.36.004.696-.184.88-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.88-.496H7.032v-2H12.5c.36.004.696-.184.88-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.88-.496H7.032v-.281c0-4.805 3.984-8.688 8.968-8.688ZM.813 18.125A.87.87 0 0 0 .125 19v5c0 7.703 5.547 14.168 12.844 15.594V46h-6.5C4.567 46 3 47.57 3 49.469v1.468h25.875V49.47c0-1.899-1.566-3.469-3.468-3.469h-6.375v-6.406c7.296-1.426 12.843-7.89 12.843-15.594v-5a.874.874 0 0 0-.875-.875.874.874 0 0 0-.875.875v5c0 7.8-6.324 14.125-14.125 14.125-7.8 0-14.125-6.324-14.125-14.125v-5a.872.872 0 0 0-.285-.656.862.862 0 0 0-.683-.219H.813Zm14.219 21.688c.32.019.644.062.968.062.325 0 .649-.043.97-.063v6.157h-1.938v-6.157ZM6.469 48h7.281c.082.02.164.031.25.031h4a1.02 1.02 0 0 0 .22-.031h7.187c.64 0 1.066.422 1.28.969h-21.5c.216-.547.638-.969 1.282-.969Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgAudio;
