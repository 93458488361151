import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
    '& .react-tel-input': {
      width: '100%',
      height: 50,
      fontFamily: theme.fontFamily,
      fontSize: theme.fontSizes.md,

      '& .form-control': {
        width: '100%',
        height: '100%',
        lineHeight: 'unset',
        borderRadius: theme.radius.sm,
        fontSize: theme.fontSizes.md,
        backgroundColor: theme.white,
        border: `1px solid ${theme.colors.gray[4]}`,
        '&:focus, &:focus-within': {
          outline: 'none',
          borderColor: theme.colors[theme.primaryColor][5],
        },
      },

      '& .flag-dropdown': {
        border: `1px solid ${theme.colors.gray[4]}`,
        backgroundColor: theme.colors.gray[1],
      },
    },
  },
}));
