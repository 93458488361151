import React from 'react';
import Audio from './Audio';
import Default from './Default';
import Folder from './Folder';

export interface MediaIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'audio' | 'default' | 'folder';
  size?: number;
}

function MediaIcon({ variant = 'audio', size, ...props }: MediaIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    case 'default':
      return <Default {...dimensions} {...props} />;
    case 'folder':
      return <Folder {...dimensions} {...props} />;
    default:
      return <Audio {...dimensions} {...props} />;
  }
}

export default React.memo(MediaIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
