import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 118 51"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={32.5} cy={25.5} r={25.5} fill="#32D74B" fillOpacity={0.15} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.711.751h11.503v18.132c0 1.4.8 2.495 2.5 2.495h5.334c.592 2.228.502 2.195 1.335 5.156H62.711V.75Zm16.504 0h10.502l5.501 20.294L112.055.751h4.501L95.385 26.534h-9.003L79.214.75h0Zm-52.012 0c4.345 6.305 11.877 17.127 12.305 17.823.791 1.289 2.498 2.804 4.033 2.804h4.333V.75h11.72v25.783H45.042c-5.616 0-8.034-5.341-11.836-11.312C32.252 13.727 25.59 3.936 23.37.75h3.834v.001Zm-26.197 0h15.333c4.79 0 5.196 2.693 9.163 8.735 6.534 9.96 7.053 10.65 7.053 11.98 0 0 .023.317 0 .518-.376 3.15-2.642 4.746-4.292 4.747L1 26.75 1.006.752v0Zm9.329 2.673h5.334c2.349 0 3.983 3.504 7 8.113 5.2 7.925 5.5 8.236 5.5 9.322 0 .983-.747 2.417-2.334 2.417h-15.5V3.424Z"
        fill="transparent"
        stroke="#32D74B"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgDefault;
