import * as React from 'react';
import { SVGProps } from 'react';

function SvgOutline(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 8.531c-5.215 0-9.629 3.121-11.75 7.531-1.129-.777-2.438-1.343-3.906-1.343-3.727 0-6.7 2.949-6.906 6.625C2.71 22.687 0 26.164 0 30.344 0 35.672 4.328 40 9.656 40h31.438C46 40 50 36 50 31.094c0-4.551-3.492-8.168-7.906-8.688.015-.242.062-.468.062-.718C42.156 14.438 36.25 8.53 29 8.53Zm0 2a11.145 11.145 0 0 1 11.156 11.156c0 .457-.035.907-.093 1.375l-.157 1.125h1.188A6.893 6.893 0 0 1 48 31.095 6.893 6.893 0 0 1 41.094 38H9.656A7.631 7.631 0 0 1 2 30.344a7.682 7.682 0 0 1 5.625-7.407l.75-.187-.031-.813c-.004-.14 0-.21 0-.218 0-2.778 2.222-5 5-5 1.39 0 2.656.558 3.562 1.468L18 19.313l.531-1.468c1.575-4.25 5.66-7.313 10.469-7.313Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgOutline;
