import React from 'react';
import { usePublicTranslation } from 'features/localization';
import Animated from '../../images/memojis/honza_animated.gif';
import Static from '../../images/memojis/honza_static.gif';
import { Memoji } from './Memoji';

export function JanMemoji() {
  const { t } = usePublicTranslation();
  return (
    <Memoji
      src={Static}
      animatedSrc={Animated}
      name={t('team.jan.name')}
      title={t('team.jan.title')}
      color="rgba(50, 215, 75, 0.15)"
      backdrop="right"
    />
  );
}
