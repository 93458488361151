import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 46 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 0a3.016 3.016 0 0 0-2.969 3.438L3.72 14H.906A.996.996 0 0 0 0 15v24c0 .55.45 1 1 1h44c.55 0 1-.45 1-1V15c0-.55-.45-1-1-1h-2.719L25.97 3.437A3.016 3.016 0 0 0 23 0Zm0 2c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1Zm-2.031 3.219A2.999 2.999 0 0 0 23 6c.773 0 1.496-.29 2.031-.781L38.625 14H7.375l13.594-8.781ZM2 16h1.688a.985.985 0 0 0 .5 0h37.656c.152.04.316.04.468 0H44v22H2V16Zm7.5 5A3.514 3.514 0 0 0 6 24.5v5C6 31.422 7.578 33 9.5 33s3.5-1.578 3.5-3.5v-5c0-1.922-1.578-3.5-3.5-3.5Zm5.5 0v12h2v-4h1c2.2 0 4-1.8 4-4s-1.8-4-4-4h-3Zm9 0v12h6v-2h-4v-3h3v-2h-3v-3h4v-2h-6Zm8 0v12h2v-7.5l2.031 6.781.219.719H39V21h-2v7.5l-2.031-6.781L34.75 21H32ZM9.5 23c.84 0 1.5.66 1.5 1.5v5c0 .84-.66 1.5-1.5 1.5S8 30.34 8 29.5v-5c0-.84.66-1.5 1.5-1.5Zm7.5 0h1c1.117 0 2 .883 2 2s-.883 2-2 2h-1v-4Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
