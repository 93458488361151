import React, { useContext, useMemo } from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { LocaleContext, usePublicTranslation } from 'features/localization';
import useStyles from '../Header.styles';

export interface LanguageSwitcherProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'to'> {}

export function LanguageSwitcher({ ref, className, ...others }: LanguageSwitcherProps) {
  const { t } = usePublicTranslation();
  const { classes, cx } = useStyles({ hasRadius: true });
  const { locale, routeAlternatives } = useContext(LocaleContext);
  const to = useMemo(
    () => routeAlternatives[locale === 'en' ? 'de' : 'en'],
    [locale, routeAlternatives]
  );

  return (
    <GatsbyLink
      to={to}
      ref={ref as any}
      hrefLang={locale === 'en' ? 'de' : 'en'}
      title={t('site.switchLanguage')}
      className={cx(classes.navLink, className)}
      {...others}
    >
      {t('language')}
    </GatsbyLink>
  );
}
