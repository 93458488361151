import React from 'react';
import { usePublicTranslation } from 'features/localization';
import Animated from '../../images/memojis/eliska_animated.gif';
import Static from '../../images/memojis/eliska_static.gif';
import { Memoji } from './Memoji';

export function EliskaMemoji() {
  const { t } = usePublicTranslation();
  return (
    <Memoji
      src={Static}
      animatedSrc={Animated}
      name={t('team.eliska.name')}
      title={t('team.eliska.title')}
      color="rgba(255, 82, 54, 0.15)"
      backdrop="left"
    />
  );
}
