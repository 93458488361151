import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 5C13.965 5 5 13.965 5 25c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879 0-9.953 8.047-18 18-18 6.246 0 11.727 3.18 14.957 8H33a1.006 1.006 0 0 0-.879.496 1.01 1.01 0 0 0 0 1.008c.184.312.52.5.88.496h10V7a1.003 1.003 0 0 0-1.017-1.016c-.55.012-.992.465-.984 1.016v6.012C37.348 8.148 31.54 5 25 5Zm18.984 18.984c-.55.012-.992.465-.984 1.016 0 9.953-8.047 18-18 18-6.246 0-11.73-3.18-14.957-8H17c.36.008.695-.184.879-.492.18-.313.18-.7 0-1.012-.184-.309-.52-.5-.879-.496H8.445a1.074 1.074 0 0 0-.386 0H7v10c-.004.36.184.695.496.879.313.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-6.016C12.65 41.848 18.461 45 25 45c11.035 0 20-8.965 20-20a1.003 1.003 0 0 0-1.016-1.016Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
