import React from 'react';
import { AviNumberSize, Card, ThemeIcon } from '@aviationexam/core';
import useStyles from './IconCard.styles';

export interface IconCardProps extends React.ComponentPropsWithoutRef<'div'> {
  size?: AviNumberSize;
  iconVariant?: 'info' | 'danger';
  icon: React.ReactNode;
}

export function IconCard({
  children,
  size = 'md',
  iconVariant = 'info',
  className,
  icon,
  ...others
}: IconCardProps) {
  const { classes, cx } = useStyles({ size });

  return (
    <div className={cx(classes.root, className)} {...others}>
      <ThemeIcon
        size="xl"
        className={cx(classes.icon, {
          [classes.iconInfo]: iconVariant === 'info',
          [classes.iconDanger]: iconVariant === 'danger',
        })}
        variant="gradient"
      >
        {icon}
      </ThemeIcon>
      <Card className={classes.card}>{children}</Card>
    </div>
  );
}
