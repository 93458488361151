import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 59 60"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 7.006C18.313 7.006 8.006 17.313 8.006 30S18.313 52.994 31 52.994 53.994 42.687 53.994 30 43.687 7.006 31 7.006Zm0 1.988c11.613 0 21.006 9.393 21.006 21.006S42.613 51.006 31 51.006 9.994 41.613 9.994 30 19.387 8.994 31 8.994Zm12.494 6.77-5.205 5.963c.385.264.718.598.98.984l5.965-5.205a6.66 6.66 0 0 0-1.74-1.742ZM31 21.006c-4.955 0-8.994 4.039-8.994 8.994s4.039 8.994 8.994 8.994 8.994-4.039 8.994-8.994-4.039-8.994-8.994-8.994Zm0 1.988A6.99 6.99 0 0 1 38.006 30 6.99 6.99 0 0 1 31 37.006 6.99 6.99 0 0 1 23.994 30 6.99 6.99 0 0 1 31 22.994ZM31 28a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-8.254 9.238-6.03 5.274a6.652 6.652 0 0 0 1.74 1.742l5.273-6.031a3.77 3.77 0 0 1-.983-.985Z"
        fill="#FF5236"
      />
      <path
        d="m51.96 56.325 1.05 1.07 1.07-1.052 1.427-1.402 1.07-1.05-1.051-1.07L7.496 3.948l-1.05-1.07-1.07 1.051-1.427 1.402-1.07 1.051 1.051 1.07 48.03 48.872Z"
        fill="#FF5236"
        stroke="transparent"
        strokeWidth={3}
      />
    </svg>
  );
}

export default SvgDefault;
