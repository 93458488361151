import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    backgroundColor: theme.colors.blue[9],
    zIndex: 10,
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    paddingLeft: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
  },
  text: {
    color: theme.white,
  },
  button: {
    textTransform: 'uppercase',
  },
}));
