import React from 'react';
import Default from './Default';

export interface AstronautIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'default';
  size?: number;
}

function AstronautIcon({ variant = 'default', size, ...props }: AstronautIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    default:
      return <Default {...dimensions} {...props} />;
  }
}

export default React.memo(AstronautIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
