import { createStyles } from '@aviationexam/core';

interface HeaderStyles {
  hasRadius: boolean;
}

export default createStyles((theme, { hasRadius }: HeaderStyles) => ({
  root: {
    height: '100%',
    backgroundColor: theme.white,
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    borderRadius: hasRadius ? theme.radius.md : 0,
    boxShadow:
      '0px 2.76726px 2.21381px rgba(11, 46, 115, 0.0196802), 0px 6.6501px 5.32008px rgba(11, 46, 115, 0.0282725), 0px 12.5216px 10.0172px rgba(11, 46, 115, 0.035), 0px 22.3363px 17.869px rgba(11, 46, 115, 0.0417275), 0px 41.7776px 33.4221px rgba(11, 46, 115, 0.0503198), 0px 100px 80px rgba(11, 46, 115, 0.07)',
  },
  logo: {
    verticalAlign: 'middle',
  },
  logoLarge: {
    display: 'block',
    ['@media (max-width: 1060px)']: {
      display: 'none',
    },
  },
  logoSmall: {
    display: 'none',
    ['@media (max-width: 1060px)']: {
      display: 'block',
    },
  },
  nav: {
    flexGrow: 1,
    alignSelf: 'stretch',
  },
  navLink: {
    ...theme.fn.focusStyles(),
    textDecoration: 'none',
    textTransform: 'uppercase',
    transition: 'color 0.2s cubic-bezier(0.64, 0, 0.35, 1)',
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.xs,
    padding: theme.spacing.xs,
    fontWeight: 700,
    letterSpacing: 1,
    '&:hover, &:focus': {
      color: theme.colors.gray[6],
    },
  },
  burger: {
    '& div, & div:before, & div:after': {
      backgroundColor: theme.colors.gray[7],
    },
  },
  signUpLink: {
    backgroundColor: theme.colors.blue[7],
  },
  dropdownNav: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'nowrap',
    ['@media (max-width: 980px)']: {
      display: 'none',
    },
  },
  mobileNav: {
    display: 'none',
    flexGrow: 1,
    ['@media (max-width: 980px)']: {
      display: 'flex',
    },
  },
}));
