import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#default_svg__a)">
        <path
          d="M25 0c-5.645 0-10.76 1.016-14.553 2.723C8.551 3.576 6.978 4.6 5.842 5.81 4.705 7.02 4 8.457 4 10v29c0 5.607 9.225 10 21 10 4.462 0 8.554-.633 11.92-1.72l-1.283-1.69C32.693 46.458 29.094 47 25 47c-11.365 0-19-4.137-19-8v-4.695C9.324 37.697 16.485 40 25 40c2.116 0 4.145-.147 6.057-.412l-1.403-1.842c-1.47.16-3.018.254-4.654.254-11.365 0-19-4.137-19-8v-5.662c1.123 1.143 2.631 2.122 4.447 2.94C14.24 28.983 19.355 30 25 30c5.645 0 10.76-1.016 14.553-2.723 1.816-.817 3.324-1.796 4.447-2.94V27h2V10c0-1.543-.705-2.98-1.842-4.19-1.136-1.209-2.709-2.234-4.605-3.087C35.76 1.016 30.646 0 25 0Zm0 2c5.402 0 10.287.997 13.732 2.547 1.723.775 3.08 1.688 3.967 2.633C43.587 8.124 44 9.057 44 10c0 .943-.413 1.876-1.3 2.82-.888.945-2.245 1.858-3.968 2.633C35.287 17.003 30.402 18 25 18c-5.402 0-10.287-.997-13.732-2.547-1.723-.775-3.08-1.688-3.967-2.633C6.413 11.876 6 10.943 6 10c0-.943.413-1.876 1.3-2.82.888-.945 2.245-1.858 3.968-2.633C14.713 2.997 19.598 2 25 2ZM6 14.338c1.123 1.143 2.631 2.122 4.447 2.94C14.24 18.983 19.355 20 25 20c5.645 0 10.76-1.016 14.553-2.723 1.816-.817 3.324-1.796 4.447-2.94V20c0 .943-.413 1.876-1.3 2.82-.888.945-2.245 1.858-3.968 2.633C35.287 27.003 30.402 28 25 28c-5.402 0-10.287-.997-13.732-2.547-1.723-.775-3.08-1.688-3.967-2.633C6.413 21.876 6 20.943 6 20v-5.662ZM38 29v10h-4.935L41.5 50l8.58-11H45V29h-7Zm2 2h3v10h3.065L41.5 46.693 37.096 41H40V31Z"
          fill="#FF5236"
        />
      </g>
      <defs>
        <clipPath id="default_svg__a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefault;
