import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { Layout } from './src/components';

import './src/features/localization/i18n';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <Layout locale={element.props.pageContext.locale}>{element}</Layout>;
};
