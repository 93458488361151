import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import {
  Grid,
  Col,
  Title,
  Container,
  TextInput,
  Button,
  Text,
  Transition,
  Group,
  ActionIcon,
} from '@aviationexam/core';
import { FacebookIcon, LinkedInIcon, TwitterIcon, YoutubeIcon } from '@aviationexam/icons';
import {
  ABOUT_PAGE,
  BOOKS_PAGE,
  CONTACT_PAGE,
  CUSTOMER_REVIEWS_PAGE,
  DOWNLOADS_PAGE,
  ES_PAGE,
  FEATURES_PAGE,
  GDPR_PAGE,
  LMS_PAGE,
  QUESTION_BANKS_EASA_GERMAN_PAGE,
  QUESTION_BANKS_EASA_PAGE,
  QUESTION_BANKS_PPL_PAGE,
  REVIEWS_PAGE,
  SUBSCRIPTION_PAGE,
  TAC_PAGE,
  TEXTBOOKS_PAGE,
} from 'config/routes';
import { PageNode } from 'types/page';
import { LocalizedLink, usePublicTranslation, useLocale } from 'features/localization';
import { useNewsletterForm } from './use-newsletter-form';
import useStyles from './Footer.styles';

const defaultSocialLinkProps = {
  variant: 'transparent' as const,
  size: 'xl' as const,
  component: 'a' as const,
  target: '_blank',
  rel: 'noreferrer',
};

export function Footer() {
  const { t } = usePublicTranslation();
  const { classes } = useStyles();
  const {
    registerNewsletter,
    errorNewsletter,
    isSubmitNewsletterSuccessful,
    isSubmittingNewsletter,
    onSubmitNewsletter,
  } = useNewsletterForm();
  const locale = useLocale();

  const {
    allMdxPage: { edges: pageEdges },
  } = useStaticQuery<{
    allMdxPage: {
      edges: { node: PageNode }[];
    };
  }>(graphql`
    query FooterLinks {
      allMdxPage(filter: { frontmatter: { footerLink: { eq: true } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              locale
            }
          }
        }
      }
    }
  `);

  const footerPages = pageEdges
    .map(({ node }) => node)
    .filter(({ frontmatter }) => frontmatter.locale === locale);

  return (
    <footer className={classes.root}>
      <div className={classes.newsletter}>
        <div className={classes.blendLeft} />
        <div className={classes.blendRight} />
        <Container size="lg">
          <Grid gutter="xl">
            <Col span={12}>
              <Title order={4} className={classes.newsletterTitle}>
                {t('footer.newsletter.title')}
              </Title>
            </Col>
            <Col span={12} lg={8}>
              <form onSubmit={onSubmitNewsletter}>
                <TextInput
                  size="lg"
                  classNames={{ label: classes.newsletterSubtitle }}
                  label={t('footer.newsletter.receiveNews')}
                  placeholder={t('footer.newsletter.yourEmail')}
                  {...registerNewsletter('email', { required: true })}
                  error={errorNewsletter}
                  rightSectionWidth={100}
                  rightSection={
                    <Button
                      className={classes.newsletterFormButton}
                      uppercase
                      fullWidth
                      loading={isSubmittingNewsletter}
                      type="submit"
                    >
                      {t('footer.newsletter.submit')}
                    </Button>
                  }
                />
                <Transition mounted={isSubmitNewsletterSuccessful} transition="pop-bottom-left">
                  {styles => (
                    <Text color="green" weight={600} my="xs" style={styles}>
                      {t('footer.newsletter.submitSuccess')}
                    </Text>
                  )}
                </Transition>
              </form>
            </Col>
            <Col span={12} lg={4}>
              <Text className={classes.newsletterSubtitle}>{t('footer.newsletter.followUs')}</Text>
              <Group spacing="xl">
                <ActionIcon<'a'>
                  {...defaultSocialLinkProps}
                  href="https://www.facebook.com/Aviationexam"
                  title="AviationExam Facebook"
                >
                  <FacebookIcon size={30} color="#fff" />
                </ActionIcon>
                <ActionIcon<'a'>
                  {...defaultSocialLinkProps}
                  href="https://twitter.com/aviationexam"
                  title="AviationExam Twitter"
                >
                  <TwitterIcon size={30} color="#fff" />
                </ActionIcon>
                <ActionIcon<'a'>
                  {...defaultSocialLinkProps}
                  href="https://www.linkedin.com/company/aviationexam"
                  title="AviationExam LinkedIn"
                >
                  <LinkedInIcon variant="solid" size={30} color="#fff" />
                </ActionIcon>
                <ActionIcon<'a'>
                  {...defaultSocialLinkProps}
                  href="https://www.youtube.com/user/aviationexam"
                  title="AviationExam YouTube"
                >
                  <YoutubeIcon size={30} color="#fff" />
                </ActionIcon>
              </Group>
            </Col>
          </Grid>
        </Container>
      </div>
      <div className={classes.links}>
        <Container size="lg">
          <Grid columns={10}>
            <Col span={10} xs={5} sm={3} md={2}>
              <Text className={classes.linkTitle} my="xs">
                {t('navigation.company.title')}
              </Text>
              <Group direction="column" grow spacing="xs">
                <LocalizedLink className={classes.link} to={ABOUT_PAGE}>
                  {t('navigation.company.team.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={CONTACT_PAGE}>
                  {t('navigation.company.contact.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={REVIEWS_PAGE}>
                  {t('navigation.company.reviews.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={CUSTOMER_REVIEWS_PAGE}>
                  {t('navigation.company.customerReviews.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={GDPR_PAGE}>
                  {t('navigation.gdpr.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={TAC_PAGE}>
                  {t('navigation.termsAndConditions.title')}
                </LocalizedLink>
              </Group>
            </Col>
            <Col span={10} xs={5} sm={3} md={2}>
              <Text className={classes.linkTitle} my="xs">
                {t('navigation.products.title')}
              </Text>
              <Group direction="column" grow spacing="xs">
                <LocalizedLink className={classes.link} to={SUBSCRIPTION_PAGE}>
                  {t('navigation.products.subscription.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={BOOKS_PAGE}>
                  {t('navigation.products.books.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={TEXTBOOKS_PAGE}>
                  {t('navigation.products.textbooks.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={DOWNLOADS_PAGE}>
                  {t('navigation.products.downloads.title')}
                </LocalizedLink>
              </Group>
            </Col>
            <Col span={10} xs={5} sm={3} md={2}>
              <Text className={classes.linkTitle} my="xs">
                {t('navigation.solutions.title')}
              </Text>
              <Group direction="column" grow spacing="xs">
                <LocalizedLink className={classes.link} to={FEATURES_PAGE}>
                  {t('navigation.solutions.students.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={LMS_PAGE}>
                  {t('navigation.solutions.schools.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={ES_PAGE}>
                  {t('navigation.solutions.authorities.title')}
                </LocalizedLink>
              </Group>
            </Col>
            <Col span={10} xs={5} sm={3} md={2}>
              <Text className={classes.linkTitle} my="xs">
                {t('navigation.questionBanks.title')}
              </Text>
              <Group direction="column" grow spacing="xs">
                <LocalizedLink className={classes.link} to={QUESTION_BANKS_EASA_PAGE}>
                  {t('navigation.questionBanks.easa.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={QUESTION_BANKS_EASA_GERMAN_PAGE}>
                  {t('navigation.questionBanks.easaGerman.title')}
                </LocalizedLink>
                <LocalizedLink className={classes.link} to={QUESTION_BANKS_PPL_PAGE}>
                  {t('navigation.questionBanks.ppl.title')}
                </LocalizedLink>
              </Group>
            </Col>
            {footerPages.length > 0 && (
              <Col span={10} xs={5} sm={3} md={2}>
                <Text className={classes.linkTitle} my="xs">
                  {t('navigation.miscellaneous')}
                </Text>
                <Group direction="column" grow spacing="xs">
                  {footerPages.map(page => (
                    <LocalizedLink
                      key={page.id}
                      className={classes.link}
                      to={page.frontmatter.slug}
                    >
                      {page.frontmatter.title}
                    </LocalizedLink>
                  ))}
                </Group>
              </Col>
            )}
            <Col span={10}>
              <Text className={classes.link} my="xl">
                Aviationexam s.r.o.
              </Text>
            </Col>
          </Grid>
        </Container>
        <div className={classes.imageWrapper}>
          <StaticImage
            src="../../../images/plane_footer.png"
            alt="Aviationexam s.r.o."
            placeholder="blurred"
            layout="constrained"
            width={800}
            formats={['webp', 'auto']}
          />
        </div>
      </div>
    </footer>
  );
}
