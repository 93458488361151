import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 4C5.79 4 4 5.79 4 8v9h2V8c0-1.191.809-2 2-2h34c1.191 0 2 .809 2 2v15h2V8c0-2.21-1.79-4-4-4H8Zm.813 4A1 1 0 0 0 8 9v8h2v-7h30v13h2V9c0-.55-.45-1-1-1H8.812ZM2 18c-.523 0-1.059.184-1.438.563C.184 18.94 0 19.477 0 20v24c0 .523.184 1.059.563 1.438C.94 45.816 1.477 46 2 46h19c.523 0 1.059-.184 1.438-.563.378-.378.562-.914.562-1.437V20c0-.523-.184-1.059-.563-1.438C22.06 18.184 21.523 18 21 18H2Zm0 2h19v24H2V20Zm1.813 1A1 1 0 0 0 3 22v18c0 .55.45 1 1 1h15c.55 0 1-.45 1-1V22c0-.55-.45-1-1-1H3.812ZM5 23h13v16H5V23Zm32 1c-.523 0-1.059.184-1.438.563-.378.378-.562.914-.562 1.437v18c0 .523.184 1.059.563 1.438.378.378.914.562 1.437.562h11c.523 0 1.059-.184 1.438-.563.378-.378.562-.914.562-1.437V26c0-.523-.184-1.059-.563-1.438C49.06 24.184 48.523 24 48 24H37Zm0 2h11v18H37V26Zm1.813 1A1 1 0 0 0 38 28v12c0 .55.45 1 1 1h7c.55 0 1-.45 1-1V28c0-.55-.45-1-1-1h-7.187ZM40 29h5v10h-5V29Zm-16 3v2h10v-2H24Zm5 3v5h5v-2h-3v-1h3v-2h-5Zm-5 6v2h10v-2H24Zm-12.5.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1Zm31 0c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
