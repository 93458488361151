import React, { useState } from 'react';
import { Text } from '@aviationexam/core';
import useStyles from './Memoji.styles';

interface MemojiProps extends React.ComponentPropsWithoutRef<'div'> {
  src: string;
  animatedSrc: string;
  name: string;
  title: string;
  backdrop: 'left' | 'center' | 'right';
  color: string;
}

export function Memoji({
  src,
  animatedSrc,
  name,
  title,
  backdrop,
  color,
  className,
  ...others
}: MemojiProps) {
  const { cx, classes } = useStyles({ backdrop, color });
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={cx(classes.root, className)}
      {...others}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      <div className={classes.imageWrapper}>
        <div className={classes.imageContainer}>
          <img
            src={src}
            alt={name}
            className={cx(classes.image, {
              [classes.hiddenImage]: isHovering,
            })}
          />
          <img
            src={animatedSrc}
            alt={name}
            className={cx(classes.image, {
              [classes.hiddenImage]: !isHovering,
            })}
          />
        </div>
        <div className={classes.backdrop} />
      </div>
      <div>
        <Text align="center" weight={600}>
          {name}
        </Text>
        <Text align="center" weight={300} color="dimmed">
          {title}
        </Text>
      </div>
    </div>
  );
}
