import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 56 54"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={30.5} cy={28.5} r={25.5} fill="#06C" fillOpacity={0.15} />
      <path
        d="M10.83 0c-.55.05-.957.543-.906 1.094.05.55.543.957 1.094.906h6.562L.3 19.281a1.014 1.014 0 0 0 0 1.438c.398.398 1.039.398 1.437 0L19.018 3.437V10c-.004.36.183.695.496.879.312.18.695.18 1.008 0 .312-.184.5-.52.496-.879V0H10.83Zm21.188 4v39h14V4h-14Zm2 2h10v35h-10V6Zm-18 11v26h14V17h-14Zm2 2h10v22h-10V19Zm-18 9v15h14V28h-14Zm2 2h10v11h-10V30Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
