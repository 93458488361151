import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0c-1.094 0-2 .906-2 2v2H4c-.406 0-.852.063-1.277.36C2.297 4.655 2 5.245 2 5.792v40.25c0 .5.172 1.012.531 1.383.356.375.88.574 1.387.574h42.164c.508 0 1.031-.2 1.387-.574.36-.371.531-.883.531-1.383V5.793c0-.547-.297-1.137-.723-1.434C46.852 4.063 46.407 4 46 4h-6V2c0-1.094-.906-2-2-2h-2c-1.094 0-2 .906-2 2v2H16V2c0-1.094-.906-2-2-2h-2Zm0 2h2v6h-2V2Zm24 0h2v6h-2V2ZM4 6h6v2c0 1.094.906 2 2 2h2c1.094 0 2-.906 2-2V6h18v2c0 1.094.906 2 2 2h2c1.094 0 2-.906 2-2V6h6v7H4V6Zm0 9h42v31H4V15Z"
        fill="#06C"
      />
      <path
        d="M22.203 36h-1.836v-7.375c0-.88.021-1.578.063-2.094a6.14 6.14 0 0 1-.446.414c-.172.151-.755.63-1.75 1.438l-.922-1.164 3.36-2.64h1.531V36Zm11.992-5.71c0 1.983-.32 3.457-.96 4.42-.636.965-1.61 1.446-2.922 1.446-1.271 0-2.235-.497-2.891-1.492-.656-.995-.985-2.453-.985-4.375 0-2.016.318-3.5.954-4.453.64-.958 1.614-1.438 2.922-1.438 1.276 0 2.242.5 2.898 1.5.656 1 .984 2.464.984 4.391Zm-5.898 0c0 1.556.159 2.668.476 3.335.323.667.836 1 1.54 1 .703 0 1.216-.339 1.539-1.016.328-.677.492-1.784.492-3.32 0-1.531-.164-2.638-.492-3.32-.323-.688-.836-1.032-1.54-1.032-.703 0-1.216.336-1.539 1.008-.317.672-.476 1.787-.476 3.344Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
