import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 58 53"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={32.5} cy={27.5} r={25.5} fill="#32D74B" fillOpacity={0.15} />
      <path
        d="M29 .531c-5.215 0-9.629 3.121-11.75 7.532-1.129-.778-2.438-1.344-3.906-1.344-3.727 0-6.7 2.949-6.906 6.625C2.71 14.688 0 18.164 0 22.344 0 27.672 4.328 32 9.656 32h31.438C46 32 50 28 50 23.094c0-4.551-3.492-8.168-7.906-8.688.015-.242.062-.469.062-.719C42.156 6.438 36.25.531 29 .531Zm0 2a11.145 11.145 0 0 1 11.156 11.156c0 .458-.035.907-.093 1.376l-.157 1.124h1.188A6.893 6.893 0 0 1 48 23.095 6.893 6.893 0 0 1 41.094 30H9.656A7.631 7.631 0 0 1 2 22.344a7.682 7.682 0 0 1 5.625-7.407l.75-.187-.031-.813c-.004-.14 0-.21 0-.218 0-2.778 2.222-5 5-5 1.39 0 2.656.558 3.562 1.469L18 11.312l.531-1.468C20.106 5.594 24.191 2.53 29 2.53Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
