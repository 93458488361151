import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from 'translations';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from './constants';

const options: InitOptions = {
  fallbackLng: DEFAULT_LOCALE,
  ns: ['common'],
  defaultNS: 'common',
  resources,
  debug: process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'remote',
  appendNamespaceToMissingKey: false,
  parseMissingKeyHandler: key => `?_${key}_?`,
  interpolation: {
    escapeValue: false,
  },
  preload: SUPPORTED_LOCALES,
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
    nsMode: 'default',
  },
};

i18n.use(initReactI18next).init(options);

export default i18n;
