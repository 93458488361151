import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#default_svg__a)">
        <path
          d="M24.875 7a1.007 1.007 0 0 0-.156.031l-24 7A.997.997 0 0 0 0 15v25c0 .55.45 1 1 1h48c.55 0 1-.45 1-1V15a.997.997 0 0 0-.719-.969l-24-7A1.02 1.02 0 0 0 24.875 7ZM25 9.062l23 6.688V39h-5V24c0-.55-.45-1-1-1H7.906A.996.996 0 0 0 7 24v15H2V15.75l23-6.688ZM9 25h32v14H9V25Z"
          fill="#FF5236"
        />
      </g>
      <defs>
        <clipPath id="default_svg__a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefault;
