import React from 'react';
import { Control, Path, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import de from 'react-phone-input-2/lang/de.json';
import { useLocale } from 'features/localization';
import useStyles from './PhoneNumberInput.styles';

import 'react-phone-input-2/lib/style.css';

export interface PhoneNumberInputProps<T> extends React.ComponentPropsWithoutRef<'div'> {
  control: Control<T>;
  name: Path<T>;
  placeholder?: string;
}

export function PhoneNumberInput<T extends Record<string, any> = {}>({
  control,
  name,
  placeholder = '',
  className,
  ...others
}: PhoneNumberInputProps<T>) {
  const locale = useLocale();
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)} {...others}>
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, onChange, ...rest } }) => (
          <PhoneInput
            localization={locale === 'de' ? de : undefined}
            preferredCountries={['de', 'cz', 'uk', 'us']}
            dropdownClass="dropdown-class"
            {...rest}
            placeholder={placeholder}
            onChange={val => onChange(val)}
          />
        )}
      />
    </div>
  );
}
