import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25} cy={25} r={23} stroke="#06C" strokeWidth={2} />
      <circle cx={25} cy={25} r={15} stroke="#06C" strokeWidth={2} />
      <circle cx={25} cy={25} r={6} stroke="#06C" strokeWidth={2} />
    </svg>
  );
}

export default SvgDefault;
