import React from 'react';
import { Group, Text, Title, Tooltip } from '@aviationexam/core';
import { CheckIcon, CloseIcon, InfoCircleIcon } from '@aviationexam/icons';
import useStyles from './FeaturesList.styles';

interface FeatureDescription {
  label: string;
  description?: string;
  isIncluded: boolean;
}

interface FeaturesListProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  icon?: React.ReactNode;
  title: React.ReactNode;
  features: FeatureDescription[];
  actions?: React.ReactNode;
  highlight?: 'left' | 'right';
}

export function FeaturesList({
  title,
  icon,
  highlight = 'left',
  features,
  actions,
  className,
  ...others
}: FeaturesListProps) {
  const { cx, classes, theme } = useStyles({ highlight });

  return (
    <div className={cx(classes.root, className)} {...others}>
      <Group direction="column" position="center" spacing="xl">
        {icon}
        {typeof title === 'string' ? (
          <Title order={3} align="center">
            {title}
          </Title>
        ) : (
          title
        )}
        <div className={classes.featuresWrapper}>
          {features.map((feature, i) => (
            <Group
              key={feature.label}
              className={cx(classes.featureRow, { [classes.borderTop]: i !== 0 })}
              noWrap
            >
              {feature.isIncluded && <CheckIcon color={theme.colors.green[7]} />}
              {!feature.isIncluded && <CloseIcon color={theme.colors.red[7]} />}
              <Text
                className={cx(classes.grow, {
                  [classes.dimmedColor]: !feature.isIncluded,
                })}
              >
                {feature.label}
              </Text>
              <Tooltip
                label={feature.description}
                disabled={!feature.description}
                wrapLines
                width={250}
                className={cx({ [classes.noShow]: !feature.description })}
              >
                <InfoCircleIcon color={theme.colors.gray[6]} />
              </Tooltip>
            </Group>
          ))}
        </div>
        {actions}
      </Group>
    </div>
  );
}
