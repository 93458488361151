import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 51"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25} cy={26} r={25} fill="#FF5236" fillOpacity={0.15} />
      <path
        d="M0 0v36h50V0H0Zm2 2h46v2.506L28.336 21.643c-1.297 1.129-2.619 1.359-3.336 1.359-.717 0-2.039-.23-3.336-1.36C20.186 20.357 2.424 4.877 2 4.507V2Zm0 5.15c2.438 2.126 16.968 14.792 18.354 15.998 1.7 1.481 3.5 1.85 4.646 1.85 1.145 0 2.946-.369 4.646-1.85C31.032 21.942 45.563 9.278 48 7.152V34H2V7.15Z"
        fill="#FF5236"
      />
    </svg>
  );
}

export default SvgDefault;
