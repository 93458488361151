import React from 'react';
import { createStyles, Text } from '@aviationexam/core';

const useStyles = createStyles(theme => ({
  value: {
    fontSize: 80,
    fontWeight: 300,
    color: theme.colors.blue[7],
    textShadow:
      'rgb(38 84 175 / 32%) 0px 143px 80px, rgb(38 84 175 / 23%) 0px 59.742px 33.4221px, rgb(38 84 175 / 19%) 0px 31.9409px 17.869px, rgb(38 84 175 / 16%) 0px 17.9058px 10.0172px, rgb(38 84 175 / 13%) 0px 9.50965px 5.32008px, rgb(38 84 175 / 9%) 0px 3.95718px 2.21381px;',
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      fontSize: 60,
    },
    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      fontSize: 40,
    },
  },
  label: {
    fontSize: theme.fontSizes.md,
    fontWeight: 600,
    textAlign: 'center',
  },
}));

export interface StatIndicatorProps extends React.ComponentPropsWithoutRef<'div'> {
  value: React.ReactNode;
  label: string;
}

export function StatIndicator({ value, label, ...others }: StatIndicatorProps) {
  const { classes } = useStyles();

  return (
    <div {...others}>
      <Text className={classes.value}>{value}</Text>
      <Text className={classes.label}>{label}</Text>
    </div>
  );
}
