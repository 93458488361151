import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#default_svg__a)">
        <path
          d="M16.375-.031A1.17 1.17 0 0 0 16.25 0a1.004 1.004 0 0 0-.719.621.991.991 0 0 0 .125.941l2.125 3.188c-3.597 1.89-6.18 5.152-6.781 9.031V14c-.004.05-.004.105 0 .156v1.406A3.929 3.929 0 0 0 9 15c-2.2 0-4 1.8-4 4v12c0 2.2 1.8 4 4 4a3.93 3.93 0 0 0 2-.563V37c0 1.645 1.355 3 3 3h1v6c0 2.2 1.8 4 4 4s4-1.8 4-4v-6h4v6c0 2.2 1.8 4 4 4s4-1.8 4-4v-6h1c1.645 0 3-1.355 3-3v-2.563A3.93 3.93 0 0 0 41 35c2.2 0 4-1.8 4-4V19c0-2.2-1.8-4-4-4-.734 0-1.406.215-2 .563v-1.376a1.132 1.132 0 0 0 0-.28v-.063c-.582-3.907-3.164-7.196-6.781-9.094l2.125-3.188a.993.993 0 0 0 .023-1.136 1 1 0 0 0-1.68.012l-2.343 3.468C28.695 3.312 26.883 3 25 3c-1.883 0-3.695.312-5.344.906L17.313.437c-.2-.32-.563-.5-.938-.468ZM25 5c1.879 0 3.64.367 5.188 1.031.03.012.062.024.093.031C33.411 7.434 35.688 10 36.563 13H13.436c.864-2.957 3.094-5.492 6.157-6.875a.84.84 0 0 0 .187-.094C21.336 5.36 23.11 5 25 5Zm-5.5 3c-.832 0-1.5.672-1.5 1.5 0 .832.668 1.5 1.5 1.5a1.5 1.5 0 0 0 0-3Zm11 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM13 15h24v22c0 .563-.438 1-1 1h-7.813a1.014 1.014 0 0 0-.406 0H16.188a1.014 1.014 0 0 0-.407 0H14c-.563 0-1-.438-1-1V15Zm-4 2c1.117 0 2 .883 2 2v12c0 1.117-.883 2-2 2s-2-.883-2-2V19c0-1.117.883-2 2-2Zm32 0c1.117 0 2 .883 2 2v12c0 1.117-.883 2-2 2s-2-.883-2-2V19c0-1.117.883-2 2-2ZM17 40h4v6c0 1.117-.883 2-2 2s-2-.883-2-2v-6Zm12 0h4v6c0 1.117-.883 2-2 2s-2-.883-2-2v-6Z"
          fill="#32D74B"
        />
      </g>
      <defs>
        <clipPath id="default_svg__a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefault;
