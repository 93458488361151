import React from 'react';
import { Helmet } from 'react-helmet';
import { DEFAULT_LOCALE } from 'features/localization';
import favicon from '../../images/favicon.png';

interface SEOProps {
  title: string;
  lang?: string;
  description?: string;
  meta?: { name?: string; property?: string; content?: string }[];
  children?: React.ReactNode;
}

export function SEO({ title, lang, description = '', meta = [], children }: SEOProps) {
  return (
    <Helmet
      htmlAttributes={{
        lang: lang ?? DEFAULT_LOCALE,
      }}
      title={title}
      titleTemplate={`%s | ${'Aviation Exam'}`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:locale',
          content: lang ?? DEFAULT_LOCALE,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        ...meta,
      ]}
    >
      <link rel="icon" href={favicon} />
      {children}
    </Helmet>
  );
}
