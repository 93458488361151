import React from 'react';
import { usePublicTranslation } from 'features/localization';
import Animated from '../../images/memojis/alex_animated.gif';
import Static from '../../images/memojis/alex_static.gif';
import { Memoji } from './Memoji';

export function AlexMemoji() {
  const { t } = usePublicTranslation();
  return (
    <Memoji
      src={Static}
      animatedSrc={Animated}
      name={t('team.alex.name')}
      title={t('team.alex.title')}
      color="rgba(0, 102, 204, 0.15)"
      backdrop="center"
    />
  );
}
