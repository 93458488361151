import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing.lg,
  },
  swiperWrapper: {
    width: '100%',
  },
  controls: {
    display: 'flex',
    gap: theme.spacing.sm,
  },
  control: {
    height: 8,
    width: 50,
    borderRadius: theme.radius.xl,
    backgroundColor: theme.colors.gray[4],
    transitionTimingFunction: theme.transitionTimingFunction,
    transitionProperty: 'background-color',
    transitionDuration: '0.2s',
    '&:hover': {
      backgroundColor: theme.colors.blue[7],
    },
  },
  controlActive: {
    backgroundColor: theme.colors.blue[7],
    boxShadow: theme.shadows.lg,
  },
}));
