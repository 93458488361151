import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 56"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25} cy={31} r={25} fill="#06C" fillOpacity={0.15} />
      <path
        d="M25 0C16.176 0 9 7.176 9 16c0 6.344 3.863 13.664 7.656 19.5 3.793 5.836 7.594 10.156 7.594 10.156.191.215.465.34.75.34s.559-.125.75-.34c0 0 3.8-4.328 7.594-10.156C37.137 29.672 41 22.375 41 16c0-8.824-7.176-16-16-16Zm0 2c7.742 0 14 6.258 14 14 0 5.54-3.637 12.742-7.344 18.438-3.11 4.773-5.734 7.917-6.656 9-.918-1.083-3.543-4.243-6.656-9.032C14.637 28.703 11 21.5 11 16c0-7.742 6.258-14 14-14Zm0 7.844-.5.312-7.5 4.5 1 1.688V24h14v-7.656l1-1.688-7.5-4.5-.5-.312Zm0 2.312 5 3V22h-3v-5h-4v5h-3v-6.844l5-3Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
