import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { oneLine, stripIndent } from 'common-tags';

interface PageDataLayer {
  web: {
    lang: 'en' | 'de';
    currency?: 'EUR' | 'USD' | 'GBP';
  };
  user?: {
    id?: number;
    type?: 'anonym' | 'student' | 'school' | 'authorities' | 'internal';
  };
  page: {
    type: 'home' | 'product' | 'cart' | 'purchase' | 'other';
    section:
      | 'home'
      | 'company'
      | 'solutions'
      | 'products'
      | 'question banks'
      | 'news'
      | 'free test'
      | 'cart'
      | 'purchase'
      | 'other';
  };
  reset?: {
    referral?: boolean;
  };
}

export const useDataLayer = (data: PageDataLayer) => {
  const [dataLayer, setDataLayer] = useState('');
  const location = useLocation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDataLayer(stripIndent`
      window.dataLayer.push({
        web: { lang: '${data.web.lang}', currency: '${data.web.currency ?? 'EUR'}' },
        user: { id: ${data.user?.id}, type: '${data.user?.type ?? 'anonym'}' },
        page: {
          type: '${data.page.type}',
          section: '${data.page.section}',
          title: '${document.title}',
          path: '${location.pathname}',
          url: '${location.href}'
        },
        product: { id: undefined, priceVat: undefined },
        order: { id: undefined, valueWithoutVat: undefined, type: undefined },
        reset: { referral: false }
      })
    `);
    }, 50);
    return () => clearTimeout(timeout);
  }, [data, location]);

  if (!dataLayer) {
    return null;
  }

  return <script>{oneLine`${dataLayer}`}</script>;
};
