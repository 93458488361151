import { usePublicTranslation } from 'features/localization';
import { useForm } from 'react-hook-form';
import { publicUserNotificationClient } from 'utils/api';

interface NewsletterForm {
  email: string;
}

export function useNewsletterForm() {
  const { t } = usePublicTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<NewsletterForm>({ defaultValues: { email: '' } });

  const onSubmit = async (data: NewsletterForm) => {
    try {
      await publicUserNotificationClient.newsletterSignIn({ email: data.email });
    } catch (error) {
      setError('email', { message: t('footer.newsletter.submitError') });
    }
  };

  return {
    registerNewsletter: register,
    onSubmitNewsletter: handleSubmit(onSubmit),
    errorNewsletter: errors.email?.message,
    isSubmittingNewsletter: isSubmitting,
    isSubmitNewsletterSuccessful: isSubmitSuccessful,
  };
}
