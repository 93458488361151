import { createStyles } from '@aviationexam/core';
import { HEADER_HEIGHT } from 'components/Layout/Layout.styles';

export const IMAGE_WRAP_BREAKPOINT = 800;

interface PageHeaderStyles {
  offsetHeader: boolean;
  color?: string;
  shadow?: string;
  backdrop?: string;
}

export default createStyles(
  (theme, { offsetHeader, color, shadow, backdrop }: PageHeaderStyles) => ({
    root: {
      background:
        backdrop ??
        'linear-gradient(180deg, #EFF0EF 0%, #ECEDED 0.01%, #D4D3D1 27.08%, #C8C7C6 44.46%, #CDCDCC 62.49%, #E9EAEA 79.62%, #FAFBFC 100%), #FAFBFC',
      paddingTop: offsetHeader ? HEADER_HEIGHT : 0,
    },
    headerContainer: {
      width: '100%',
      maxWidth: 1100,
      margin: '0 auto',
      paddingLeft: theme.spacing.md,
      paddingRight: theme.spacing.md,
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      [theme.fn.smallerThan('sm')]: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
      },
    },
    header: {
      borderRadius: theme.radius.md,
      background: color ?? '#17262D',
      boxShadow:
        shadow ??
        '0px 100px 80px rgba(10, 18, 22, 0.07), 0px 41.7776px 33.4221px rgba(10, 18, 22, 0.0503198), 0px 22.3363px 17.869px rgba(10, 18, 22, 0.0417275), 0px 12.5216px 10.0172px rgba(10, 18, 22, 0.035), 0px 6.6501px 5.32008px rgba(10, 18, 22, 0.0282725), 0px 2.76726px 2.21381px rgba(10, 18, 22, 0.0196802)',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing.xl * 2,
      color: theme.white,
      order: 1,
      [theme.fn.smallerThan('md')]: {
        padding: theme.spacing.xl,
      },
      [theme.fn.smallerThan(IMAGE_WRAP_BREAKPOINT)]: {
        padding: theme.spacing.lg,
        order: 2,
      },
    },
    imageWrapper: {
      order: 2,
      '& img': {
        borderTopRightRadius: theme.radius.md,
        borderBottomRightRadius: theme.radius.md,
      },
      [theme.fn.smallerThan(IMAGE_WRAP_BREAKPOINT)]: {
        order: 1,
        '& img': {
          borderTopLeftRadius: theme.radius.md,
          borderBottomRightRadius: 0,
        },
      },
    },
  })
);
