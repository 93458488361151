exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abort-deletion-[secret]-tsx": () => import("./../../../src/pages/abort-deletion/[secret].tsx" /* webpackChunkName: "component---src-pages-abort-deletion-[secret]-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customer-reviews-tsx": () => import("./../../../src/pages/customer-reviews.tsx" /* webpackChunkName: "component---src-pages-customer-reviews-tsx" */),
  "component---src-pages-downloads-tsx": () => import("./../../../src/pages/downloads.tsx" /* webpackChunkName: "component---src-pages-downloads-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-subscriptions-[id]-[secret]-tsx": () => import("./../../../src/pages/newsletter-subscriptions/[id]/[secret].tsx" /* webpackChunkName: "component---src-pages-newsletter-subscriptions-[id]-[secret]-tsx" */),
  "component---src-pages-products-books-tsx": () => import("./../../../src/pages/products/books.tsx" /* webpackChunkName: "component---src-pages-products-books-tsx" */),
  "component---src-pages-products-subscription-tsx": () => import("./../../../src/pages/products/subscription.tsx" /* webpackChunkName: "component---src-pages-products-subscription-tsx" */),
  "component---src-pages-products-textbooks-tsx": () => import("./../../../src/pages/products/textbooks.tsx" /* webpackChunkName: "component---src-pages-products-textbooks-tsx" */),
  "component---src-pages-question-banks-easa-german-tsx": () => import("./../../../src/pages/question-banks/easa-german.tsx" /* webpackChunkName: "component---src-pages-question-banks-easa-german-tsx" */),
  "component---src-pages-question-banks-easa-tsx": () => import("./../../../src/pages/question-banks/easa.tsx" /* webpackChunkName: "component---src-pages-question-banks-easa-tsx" */),
  "component---src-pages-question-banks-ppl-tsx": () => import("./../../../src/pages/question-banks/ppl.tsx" /* webpackChunkName: "component---src-pages-question-banks-ppl-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-solutions-app-tsx": () => import("./../../../src/pages/solutions/app.tsx" /* webpackChunkName: "component---src-pages-solutions-app-tsx" */),
  "component---src-pages-solutions-caa-tsx": () => import("./../../../src/pages/solutions/caa.tsx" /* webpackChunkName: "component---src-pages-solutions-caa-tsx" */),
  "component---src-pages-solutions-lms-tsx": () => import("./../../../src/pages/solutions/lms.tsx" /* webpackChunkName: "component---src-pages-solutions-lms-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-api-blog-category-tsx": () => import("./../../../src/templates/api-blog/Category.tsx" /* webpackChunkName: "component---src-templates-api-blog-category-tsx" */),
  "component---src-templates-api-blog-post-tsx": () => import("./../../../src/templates/api-blog/Post.tsx" /* webpackChunkName: "component---src-templates-api-blog-post-tsx" */),
  "component---src-templates-api-blog-posts-tsx": () => import("./../../../src/templates/api-blog/Posts.tsx" /* webpackChunkName: "component---src-templates-api-blog-posts-tsx" */),
  "component---src-templates-api-blog-tag-tsx": () => import("./../../../src/templates/api-blog/Tag.tsx" /* webpackChunkName: "component---src-templates-api-blog-tag-tsx" */),
  "component---src-templates-blog-blog-category-tsx": () => import("./../../../src/templates/blog/BlogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-blog-category-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-blog-blog-posts-tsx": () => import("./../../../src/templates/blog/BlogPosts.tsx" /* webpackChunkName: "component---src-templates-blog-blog-posts-tsx" */),
  "component---src-templates-blog-blog-tagged-tsx": () => import("./../../../src/templates/blog/BlogTagged.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tagged-tsx" */),
  "component---src-templates-pages-page-tsx": () => import("./../../../src/templates/pages/Page.tsx" /* webpackChunkName: "component---src-templates-pages-page-tsx" */)
}

