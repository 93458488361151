import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 58 54"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={32.5} cy={28.5} r={25.5} fill="#FF5236" fillOpacity={0.15} />
      <path
        d="M3 0C1.355 0 0 1.355 0 3v30c0 1.645 1.355 3 3 3h19.78c.548.61 1.335 1 2.22 1a2.98 2.98 0 0 0 2.22-1H47c1.645 0 3-1.355 3-3V3c0-1.645-1.355-3-3-3H27c-.777 0-1.465.342-2 .834C24.465.342 23.777 0 23 0H3Zm0 2h20c.565 0 1 .435 1 1v31H3c-.565 0-1-.435-1-1V3c0-.565.435-1 1-1Zm24 0h20c.565 0 1 .435 1 1v30c0 .565-.435 1-1 1H26V3c0-.565.435-1 1-1Zm13.99 4.986a1 1 0 0 0-.697.307L37 10.586l-3.293-3.293a1 1 0 1 0-1.414 1.414L35.586 12l-3.293 3.293a1 1 0 1 0 1.414 1.414L37 13.414l3.293 3.293a1 1 0 1 0 1.414-1.414L38.414 12l3.293-3.293a1 1 0 0 0-.717-1.72Zm-20.95.004a1 1 0 0 0-.704.262l-8.295 7.375-3.334-3.334a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.371.041l9-8a1 1 0 0 0-.625-1.758ZM6 22a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H6Zm24 0a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H30ZM6 27a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H6Zm24 0a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H30Z"
        fill="#FF5236"
      />
    </svg>
  );
}

export default SvgDefault;
