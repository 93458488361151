import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 7C15.61 7 7.07 10.729.78 16.771l-.721.694 5.537 5.767.722-.693C11.175 17.874 17.75 15 25 15c7.25 0 13.825 2.874 18.682 7.54l.722.692 5.537-5.767-.72-.694C42.93 10.73 34.39 7 25 7Zm0 2c8.5 0 16.193 3.292 22.049 8.588L44.28 20.47C39.15 15.868 32.42 13 25 13c-7.419 0-14.149 2.868-19.281 7.47L2.95 17.589C8.807 12.292 16.501 9 25 9Zm0 10c-5.953 0-11.604 2.28-15.914 6.422l-.723.691 5.559 5.791.723-.693C17.466 28.495 21.145 27 25 27c3.129 0 6.131 1 8.664 2.822a11.944 11.944 0 0 1 1.99-.998C32.625 26.356 28.898 25 25 25c-4.045 0-7.914 1.447-11.002 4.096l-2.791-2.907A20.86 20.86 0 0 1 25 21c5.101 0 9.961 1.834 13.793 5.188l-2.223 2.316a11.99 11.99 0 0 1 3.246-.496l1.82-1.895-.722-.691C36.604 21.28 30.953 19 25 19Zm15 11c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10Zm-15 1a10.943 10.943 0 0 0-7.61 3.072l-.72.694L25 43.443l3.016-3.14C28.014 40.202 28 40.102 28 40c0-1.027.144-2.02.387-2.97L25 40.556l-5.467-5.694a8.926 8.926 0 0 1 9.955-.648c.324-.587.7-1.14 1.114-1.66A10.903 10.903 0 0 0 25 31Zm15 1c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8Zm-3.5 3.5c-.25 0-.5.1-.7.3-.4.4-.4 1 0 1.4l2.8 2.8-2.8 2.8c-.4.4-.4 1 0 1.4.2.2.5.3.7.3.2 0 .5-.1.7-.3l2.8-2.8 2.8 2.8c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4L41.4 40l2.8-2.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L40 38.6l-2.8-2.8c-.2-.2-.45-.3-.7-.3Z"
        fill="#FF5236"
      />
    </svg>
  );
}

export default SvgDefault;
