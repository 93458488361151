import React, { useContext, useEffect, useRef, useCallback } from 'react';
import { createStyles, Transition } from '@aviationexam/core';
import { DropdownContext, Option } from './DropdownProvider';

const useStyles = createStyles(() => ({
  section: {
    position: 'absolute',
  },
}));

export interface DropdownSectionProps {
  option: Option;
}

export function DropdownSection({ option }: DropdownSectionProps) {
  const { classes } = useStyles();
  const { cachedId, targetId } = useContext(DropdownContext);
  const sectionRef = useRef<HTMLDivElement>(null);
  const { id, optionCenterX, contentDimensions, WrappedContent } = option;

  const contentWidth = contentDimensions?.width || 0;
  const x = optionCenterX - contentWidth / 2;
  const isActive = cachedId === id;

  const handleKeyboard = useCallback((e: KeyboardEvent) => {
    const key = e.code.toLowerCase();
    const ALLOWED_KEYS = ['ArrowDown', 'ArrowUp', 'Space', 'Enter', 'Tab'].map(s =>
      s.toLowerCase()
    );
    if (!ALLOWED_KEYS.includes(key)) {
      return;
    }
    if (key !== 'enter') {
      e.preventDefault();
    }
    const links = sectionRef.current?.getElementsByTagName('a') ?? [];
    let isFocused = false;
    for (let i = 0; i < links.length; i++) {
      isFocused = document.activeElement === links[i];
      if (isFocused) {
        break;
      }
    }
    if (!isFocused) {
      links[0]?.focus();
      return;
    }
    for (let i = 0; i < links.length; i++) {
      if (document.activeElement === links[i] && key === 'arrowdown') {
        links[i + 1]?.focus();
        break;
      }
      if (document.activeElement === links[i] && key === 'arrowup') {
        links[i - 1]?.focus();
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (typeof document === 'undefined' || targetId !== id) {
      return () => undefined;
    }

    document.addEventListener('keydown', handleKeyboard);
    return () => {
      document.removeEventListener('keydown', handleKeyboard);
    };
  }, [targetId, id, handleKeyboard]);

  return (
    <Transition
      mounted={isActive}
      transition={{
        in: { opacity: 1, transform: `translateX(${x}px)`, pointerEvents: 'unset' },
        out: { opacity: 0, transform: `translateX(${x}px)`, pointerEvents: 'none' },
        transitionProperty: 'opacity, transform, pointerEvents',
        common: {
          transitionTimingFunction: 'ease-out',
        },
      }}
      duration={200}
    >
      {styles => (
        <div className={classes.section} style={styles}>
          <WrappedContent />
        </div>
      )}
    </Transition>
  );
}
