import { useState, useCallback, useLayoutEffect } from 'react';

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

const getDimensions = (element: HTMLElement): DimensionObject => element.getBoundingClientRect();

export function useDimensions(responsive = true) {
  const [dimensions, setDimensions] = useState<DimensionObject | undefined>();
  const [element, setElement] = useState<HTMLElement | undefined>();

  const hook = useCallback(e => setElement(e), []);

  useLayoutEffect(() => {
    if (element && typeof window !== 'undefined') {
      const updateDimensions = () => {
        window.requestAnimationFrame(() => {
          setDimensions(getDimensions(element));
        });
      };

      updateDimensions();

      if (responsive) {
        window.addEventListener('resize', updateDimensions);

        return () => {
          window.removeEventListener('resize', updateDimensions);
        };
      }
    }
    return () => undefined;
  }, [element, hook, responsive]);

  return [hook, dimensions, element] as const;
}
