import React, { useRef, useState, useContext, useEffect, useCallback } from 'react';
import { createStyles } from '@aviationexam/core';
import { useDimensions } from 'hooks';
import { DropdownContext } from './DropdownProvider';

export const useStyles = createStyles(theme => ({
  option: {
    ...theme.fn.focusStyles(),
    background: 'none',
    border: 'none',
    outline: 0,
    textTransform: 'uppercase',
    height: '100%',
    transitionProperty: 'color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'cubic-bezier(0.64, 0, 0.35, 1)',
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.xs,
    padding: [theme.spacing.xs, theme.spacing.xl].map(p => `${p}px`).join(' '),
    fontWeight: 700,
    letterSpacing: 3,
    '&:hover, &:focus': {
      color: theme.colors.gray[6],
    },
    [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
      padding: theme.spacing.xs,
    },
  },
}));

let lastOptionId = 0;

export interface DropdownOptionProps {
  name: string;
  content(): JSX.Element;
  backgroundHeight?: number;
}

export function DropdownOption({
  name,
  content: Content,
  backgroundHeight = 300,
}: DropdownOptionProps) {
  const { classes } = useStyles();
  const idRef = useRef(++lastOptionId);
  const id = idRef.current;

  const [optionHook, optionDimensions] = useDimensions();
  const [registered, setRegistered] = useState(false);

  const { registerOption, updateOptionProps, deleteOptionById, setTargetId, targetId } =
    useContext(DropdownContext);

  useEffect(() => {
    if (!registered && optionDimensions) {
      const WrappedContent = () => {
        const contentRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
          const contentDimensions = contentRef.current?.getBoundingClientRect();
          updateOptionProps(id, { contentDimensions });
        }, []);

        return (
          <div ref={contentRef}>
            <Content />
          </div>
        );
      };

      registerOption({
        id,
        optionDimensions,
        optionCenterX: optionDimensions.x + optionDimensions.width / 2,
        WrappedContent,
        backgroundHeight,
      });
      setRegistered(true);
    } else if (registered && optionDimensions) {
      updateOptionProps(id, {
        optionDimensions,
        optionCenterX: optionDimensions.x + optionDimensions.width / 2,
      });
    }
  }, [
    registerOption,
    id,
    registered,
    optionDimensions,
    updateOptionProps,
    deleteOptionById,
    backgroundHeight,
    Content,
  ]);

  useEffect(() => deleteOptionById(id), [deleteOptionById, id]);

  const handleOpen = useCallback(() => {
    setTargetId(id);
  }, [setTargetId, id]);
  const handleClose = useCallback(() => {
    setTargetId(undefined);
  }, [setTargetId]);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      return targetId === id ? handleClose() : handleOpen();
    },
    [targetId, id, handleClose, handleOpen]
  );

  return (
    <li>
      <button
        type="button"
        ref={optionHook}
        className={classes.option}
        onClick={handleClick}
        onMouseDown={handleClick}
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        onFocus={handleOpen}
        onBlur={handleClose}
      >
        {name}
      </button>
    </li>
  );
}
