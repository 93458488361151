import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 65 64"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m32.5.13-1.49 2.972-3.28.446 2.385 2.303-.521 3.268L32.5 7.634l2.906 1.485-.521-3.268 2.384-2.303-3.279-.446L32.5.131ZM18.416 4.366l-1.49 2.972-3.28.445 2.385 2.303-.596 3.269 2.98-1.56 2.907 1.56-.522-3.269 2.385-2.303-3.279-.445-1.49-2.972Zm28.168 0-1.49 2.972-3.279.445 2.385 2.303-.522 3.269 2.907-1.56 2.98 1.56-.596-3.269 2.385-2.303-3.28-.445-1.49-2.972ZM8.355 13.577 6.94 16.474l-3.278.52 2.384 2.303-.596 3.268 2.906-1.56 2.981 1.56-.596-3.268 2.385-2.303-3.28-.52-1.49-2.897Zm48.29 0-1.49 2.897-3.28.52 2.385 2.303-.596 3.268 2.98-1.56 2.907 1.56-.596-3.268 2.385-2.303-3.28-.52-1.415-2.897ZM5.076 27.468l-1.49 2.971-3.279.52 2.385 2.303-.596 3.269 2.98-1.56 2.907 1.56-.522-3.269 2.31-2.303-3.279-.52-1.416-2.971Zm54.848 0-1.416 2.971-3.28.52 2.311 2.303-.522 3.269 2.907-1.56 2.98 1.56-.596-3.269 2.385-2.303-3.279-.52-1.49-2.971ZM8.355 39.799 6.94 42.771l-3.278.446 2.384 2.302-.596 3.269 2.906-1.56 2.981 1.56-.596-3.269 2.385-2.302-3.28-.446-1.49-2.972Zm48.29 0-1.49 2.972-3.28.446 2.385 2.302-.596 3.269 2.98-1.56 2.907 1.56-.596-3.269 2.385-2.302-3.28-.446-1.415-2.972Zm-38.23 9.286-1.49 2.972-3.279.52 2.385 2.302-.596 3.195 2.98-1.486 2.907 1.486-.522-3.195 2.385-2.302-3.279-.52-1.49-2.972Zm28.17 0-1.49 2.972-3.28.52 2.385 2.302-.522 3.195 2.907-1.486 2.98 1.486-.596-3.195 2.385-2.302-3.28-.52-1.49-2.972ZM32.5 54.88l-1.49 2.972-3.28.446 2.385 2.302-.521 3.269 2.906-1.56 2.906 1.56-.521-3.269 2.384-2.302-3.279-.446-1.49-2.972Z"
        fill="#F2C94C"
      />
      <g clipPath="url(#default_svg__a)">
        <path
          d="m45.67 22.074-17.356 17.3-8.186-8.159-1.974 1.968 10.16 10.125 19.33-19.266-1.974-1.968Z"
          fill="#323A45"
        />
      </g>
      <defs>
        <clipPath id="default_svg__a">
          <path fill="#fff" transform="translate(17.546 21.068)" d="M0 0h30.706v22.261H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefault;
