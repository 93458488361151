import React, { useState } from 'react';
import { UnstyledButton } from '@aviationexam/core';
import type { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useStyles from './Slideshow.styles';
import 'swiper/css';

interface SlideshowData {
  label: string;
  content: React.ReactNode;
}

interface SlideshowProps extends React.ComponentPropsWithoutRef<'div'> {
  data: SlideshowData[];
}

export function Slideshow({ data, className, ...others }: SlideshowProps) {
  const { classes, cx } = useStyles();
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [activeIndex, setActiveIndex] = useState(0);

  if (data.length <= 0) {
    return null;
  }

  return (
    <div className={cx(classes.root, className)} {...others}>
      <div className={classes.swiperWrapper}>
        <Swiper onSlideChange={s => setActiveIndex(s.activeIndex)} onSwiper={setSwiper}>
          {data.map(({ content, label }) => (
            <SwiperSlide key={label}>{content}</SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={classes.controls}>
        {data.map(({ label }, index) => (
          <UnstyledButton
            key={label}
            title={label}
            className={cx(classes.control, {
              [classes.controlActive]: index === activeIndex,
            })}
            onClick={() => {
              swiper.slideTo(index);
              setActiveIndex(index);
            }}
          />
        ))}
      </div>
    </div>
  );
}
