import * as React from 'react';
import { SVGProps } from 'react';

function SvgDe(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#1A1D21" d="M0 0h36v11H0z" />
      <path fill="#D42235" d="M0 11h36v11H0z" />
      <path fill="#FFB82B" d="M0 22h36v11H0z" />
    </svg>
  );
}

export default SvgDe;
