import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 162 163"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#default_svg__a)">
        <path
          d="M73.14.76c-.99.09-1.747.922-1.74 1.92v80.64c0 1.057.863 1.92 1.92 1.92h49.92c1.058 0 1.92-.863 1.92-1.92V2.5a1.912 1.912 0 0 0-1.92-1.74h-50.1Zm2.1 3.84h46.08v76.8H75.24V4.6ZM53.76 8.44a1.92 1.92 0 0 0-1.56 1.92v65.28c0 1.057.863 1.92 1.92 1.92h13.44v-3.84H56.04V12.28h11.52V8.44H53.76ZM38.4 16.12a1.92 1.92 0 0 0-1.56 1.92v49.92c0 1.057.863 1.92 1.92 1.92h9.6v-3.84h-7.68V19.96h7.68v-3.84H38.4Z"
          fill="#06C"
        />
      </g>
      <g filter="url(#default_svg__b)">
        <path
          d="m98.28 25.72-.8 1.61-1.76.243L97 28.82l-.28 1.773 1.56-.806 1.56.806-.28-1.773 1.28-1.248-1.76-.242-.8-1.611Zm-7.56 2.296-.8 1.61-1.76.242 1.28 1.25-.32 1.771 1.6-.845 1.56.846-.28-1.773 1.28-1.248-1.76-.242-.8-1.611Zm15.12 0-.8 1.61-1.76.242 1.28 1.25-.28 1.771 1.56-.845 1.6.846-.32-1.773 1.28-1.248-1.76-.242-.8-1.611ZM85.32 33.01l-.76 1.571-1.76.282 1.28 1.249-.32 1.772 1.56-.846 1.6.846-.32-1.772 1.28-1.249-1.76-.282-.8-1.57Zm25.92 0-.8 1.571-1.76.282 1.28 1.249-.32 1.772 1.6-.846 1.56.846-.32-1.772 1.28-1.249-1.76-.282-.76-1.57Zm-27.68 7.533-.8 1.61-1.76.283 1.28 1.248-.32 1.773 1.6-.846 1.56.846-.28-1.773 1.24-1.248-1.76-.282-.76-1.611Zm29.44 0-.76 1.61-1.76.283 1.24 1.248-.28 1.773 1.56-.846 1.6.846-.32-1.773 1.28-1.248-1.76-.282-.8-1.611Zm-27.68 6.686-.76 1.611-1.76.242 1.28 1.249-.32 1.772 1.56-.846 1.6.846-.32-1.772 1.28-1.249-1.76-.242-.8-1.61Zm25.92 0-.8 1.611-1.76.242 1.28 1.249-.32 1.772 1.6-.846 1.56.846-.32-1.772 1.28-1.249-1.76-.242-.76-1.61Zm-20.52 5.035-.8 1.611-1.76.282 1.28 1.249-.32 1.732 1.6-.806 1.56.806-.28-1.732 1.28-1.249-1.76-.282-.8-1.61Zm15.12 0-.8 1.611-1.76.282 1.28 1.249-.28 1.732 1.56-.806 1.6.806-.32-1.732 1.28-1.249-1.76-.282-.8-1.61Zm-7.56 3.142-.8 1.611-1.76.242L97 58.507l-.28 1.773 1.56-.846 1.56.846-.28-1.773 1.28-1.248-1.76-.242-.8-1.611Z"
          fill="#06C"
        />
      </g>
      <defs>
        <filter
          id="default_svg__a"
          x={0.84}
          y={0.76}
          width={160.32}
          height={161.48}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1.135} />
          <feGaussianBlur stdDeviation={0.498} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.030926 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.727} />
          <feGaussianBlur stdDeviation={1.197} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.0444282 0" />
          <feBlend in2="effect1_dropShadow_1736_0" result="effect2_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5.134} />
          <feGaussianBlur stdDeviation={2.254} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.055 0" />
          <feBlend in2="effect2_dropShadow_1736_0" result="effect3_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={9.158} />
          <feGaussianBlur stdDeviation={4.021} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.0655718 0" />
          <feBlend in2="effect3_dropShadow_1736_0" result="effect4_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={17.129} />
          <feGaussianBlur stdDeviation={7.52} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.079074 0" />
          <feBlend in2="effect4_dropShadow_1736_0" result="effect5_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={41} />
          <feGaussianBlur stdDeviation={18} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.11 0" />
          <feBlend in2="effect5_dropShadow_1736_0" result="effect6_dropShadow_1736_0" />
          <feBlend in="SourceGraphic" in2="effect6_dropShadow_1736_0" result="shape" />
        </filter>
        <filter
          id="default_svg__b"
          x={45}
          y={25.72}
          width={106.559}
          height={111.56}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1.135} />
          <feGaussianBlur stdDeviation={0.498} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.030926 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.727} />
          <feGaussianBlur stdDeviation={1.197} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.0444282 0" />
          <feBlend in2="effect1_dropShadow_1736_0" result="effect2_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5.134} />
          <feGaussianBlur stdDeviation={2.254} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.055 0" />
          <feBlend in2="effect2_dropShadow_1736_0" result="effect3_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={9.158} />
          <feGaussianBlur stdDeviation={4.021} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.0655718 0" />
          <feBlend in2="effect3_dropShadow_1736_0" result="effect4_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={17.129} />
          <feGaussianBlur stdDeviation={7.52} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.079074 0" />
          <feBlend in2="effect4_dropShadow_1736_0" result="effect5_dropShadow_1736_0" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={41} />
          <feGaussianBlur stdDeviation={18} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.11 0" />
          <feBlend in2="effect5_dropShadow_1736_0" result="effect6_dropShadow_1736_0" />
          <feBlend in="SourceGraphic" in2="effect6_dropShadow_1736_0" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgDefault;
