import * as React from 'react';
import { SVGProps } from 'react';

function SvgFilled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 72 72"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#filled_svg__a)">
        <rect width={72} height={72} rx={4} fill="#FF5236" />
        <rect
          width={72}
          height={72}
          rx={4}
          fill="url(#filled_svg__b)"
          fillOpacity={0.4}
          style={{
            mixBlendMode: 'overlay',
          }}
        />
        <g filter="url(#filled_svg__c)">
          <path
            d="M22.562 35.695a13.41 13.41 0 0 1 3.93-9.195 13.369 13.369 0 0 1 9.504-3.938c1.816 0 3.574.356 5.226 1.055a13.34 13.34 0 0 1 4.27 2.883c.387.387.75.797 1.086 1.227l-2.352 1.835a.313.313 0 0 0 .118.551l6.863 1.68a.314.314 0 0 0 .387-.3l.03-7.067a.31.31 0 0 0-.503-.246l-2.203 1.722c-3.004-3.843-7.676-6.308-12.926-6.308-8.95 0-16.23 7.172-16.398 16.086a.314.314 0 0 0 .312.32h2.344a.312.312 0 0 0 .312-.305ZM52.094 36H49.75a.312.312 0 0 0-.313.305 13.347 13.347 0 0 1-1.05 4.922 13.368 13.368 0 0 1-2.88 4.273 13.378 13.378 0 0 1-9.503 3.938 13.369 13.369 0 0 1-9.5-3.938c-.387-.387-.75-.797-1.086-1.227l2.351-1.836a.313.313 0 0 0-.117-.55l-6.863-1.68a.314.314 0 0 0-.387.3l-.027 7.071c0 .262.3.41.504.246l2.203-1.722c3.004 3.84 7.676 6.304 12.926 6.304 8.953 0 16.23-7.175 16.398-16.086a.314.314 0 0 0-.312-.32Z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="filled_svg__b"
          x1={36}
          y1={0}
          x2={36}
          y2={72}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <clipPath id="filled_svg__a">
          <path fill="#fff" d="M0 0h72v72H0z" />
        </clipPath>
        <filter
          id="filled_svg__c"
          x={-20}
          y={16}
          width={112}
          height={117}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1.135} />
          <feGaussianBlur stdDeviation={0.498} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.030926 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_767:162" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.727} />
          <feGaussianBlur stdDeviation={1.197} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.0444282 0" />
          <feBlend in2="effect1_dropShadow_767:162" result="effect2_dropShadow_767:162" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5.134} />
          <feGaussianBlur stdDeviation={2.254} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.055 0" />
          <feBlend in2="effect2_dropShadow_767:162" result="effect3_dropShadow_767:162" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={9.158} />
          <feGaussianBlur stdDeviation={4.021} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.0655718 0" />
          <feBlend in2="effect3_dropShadow_767:162" result="effect4_dropShadow_767:162" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={17.129} />
          <feGaussianBlur stdDeviation={7.52} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.079074 0" />
          <feBlend in2="effect4_dropShadow_767:162" result="effect5_dropShadow_767:162" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={41} />
          <feGaussianBlur stdDeviation={18} />
          <feColorMatrix values="0 0 0 0 0.0431373 0 0 0 0 0.180392 0 0 0 0 0.45098 0 0 0 0.11 0" />
          <feBlend in2="effect5_dropShadow_767:162" result="effect6_dropShadow_767:162" />
          <feBlend in="SourceGraphic" in2="effect6_dropShadow_767:162" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgFilled;
