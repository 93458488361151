import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createStyles } from '@aviationexam/core';
import { useIntersection, useMergedRef } from '@aviationexam/hooks';

function isSourceArray(src: Source): src is string[] | TypedSource[] {
  return Array.isArray(src);
}
function isTypedSource(src: string | TypedSource): src is TypedSource {
  return typeof src !== 'string';
}

const useStyles = createStyles(() => ({
  root: {
    width: '100%',
    height: 'auto',
    maxHeight: '100%',
  },
}));

type TypedSource = { src: string; type: string };
type Source = string | string[] | TypedSource | TypedSource[];
interface Props extends Omit<React.ComponentPropsWithRef<'video'>, 'src' | 'onEnded'> {
  src: Source;
  muted?: boolean;
  autoPlayInView?: boolean;
  onEnded?: () => void;
}
export const Video = React.forwardRef<HTMLVideoElement, Props>(
  (
    {
      autoPlay = true,
      autoPlayInView = false,
      loop = true,
      src: sources,
      muted = true,
      onEnded,
      className,
      ...rest
    },
    ref
  ) => {
    const { classes, cx } = useStyles();
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const [videoRef, observedEntry] = useIntersection({
      threshold: 1,
    });
    const mergedRef = useMergedRef(ref, localVideoRef, videoRef);
    const srcSet = useMemo(() => (isSourceArray(sources) ? sources : [sources]), [sources]);
    const [autoPlayAllowed, setAutoPlayAllowed] = useState(true);

    useEffect(() => {
      if (typeof observedEntry?.isIntersecting !== 'boolean' || !autoPlayInView) {
        return;
      }
      if (observedEntry.isIntersecting) {
        localVideoRef.current?.play().catch(() => {
          setAutoPlayAllowed(false);
        });
      } else {
        localVideoRef.current?.pause();
      }
    }, [observedEntry?.isIntersecting, autoPlayInView]);

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        ref={mergedRef}
        autoPlay={autoPlay}
        loop={loop}
        playsInline={autoPlay}
        muted={muted}
        onEnded={onEnded}
        className={cx(classes.root, className)}
        controls={!autoPlayAllowed}
        {...rest}
      >
        {srcSet.map(source => {
          const src = isTypedSource(source) ? source.src : source;
          const type = isTypedSource(source) ? source.type : undefined;
          return <source key={src} src={src} type={type} />;
        })}
      </video>
    );
  }
);
