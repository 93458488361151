import React, { useContext, useMemo, useState, useEffect } from 'react';
import { createStyles, Transition } from '@aviationexam/core';
import { DropdownContext } from './DropdownProvider';
import { DropdownSection } from './DropdownSection';

const refDuration = 0.22;

const useStyles = createStyles((theme, { offsetTop = 0 }: { offsetTop?: number }) => ({
  root: {
    perspective: 1000,
    position: 'absolute',
    top: offsetTop,
    paddingTop: theme.spacing.xs,
    left: 0,
    width: '100%',
  },
  rootContainer: {
    zIndex: 60,
    position: 'absolute',
  },
  dropdownContainer: {
    position: 'absolute',
    overflow: 'hidden',
    boxShadow:
      '0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3), 0 -18px 60px -10px rgba(0, 0, 0, 0.025)',
    backgroundColor: theme.white,
    borderRadius: theme.radius.md,
  },
  arrow: {
    position: 'relative',
    '&:before': {
      content: '" "',
      position: 'absolute',
      width: '16px',
      height: '16px',
      top: '-6.5px',
      left: '-8px',
      transform: 'rotate(45deg)',
      backgroundColor: theme.white,
      borderRadius: theme.radius.sm,
    },
  },
  background: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.white,
  },
}));

export interface DropdownArrowProps {
  isFirstInteraction: boolean;
}

export function DropdownArrow({ isFirstInteraction }: DropdownArrowProps) {
  const { classes } = useStyles({});
  const { cachedId, getOptionById } = useContext(DropdownContext);
  const cachedOption = useMemo(() => getOptionById(cachedId), [cachedId, getOptionById]);
  const x = cachedOption ? cachedOption.optionCenterX : 0;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Transition
      mounted={isMounted}
      transition={{
        in: { opacity: x > 0 ? 1 : 0, transform: `translateX(${x}px)` },
        out: { opacity: 0, transform: `translateX(${x}px)` },
        transitionProperty: 'opacity, transform',
        common: {
          pointerEvents: 'none',
          transitionTimingFunction: 'ease-out',
          transitionDuration: `${isFirstInteraction ? 0 : refDuration}s`,
        },
      }}
    >
      {styles => <div style={styles} className={classes.arrow} />}
    </Transition>
  );
}

export function DropdownBackground() {
  const { classes } = useStyles({});
  const { cachedId, getOptionById } = useContext(DropdownContext);
  const cachedOption = useMemo(() => getOptionById(cachedId), [cachedId, getOptionById]);
  const backgroundHeight = cachedOption?.backgroundHeight || 0;
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Transition
      mounted={isMounted}
      transition={{
        in: { height: backgroundHeight },
        out: { height: 0 },
        common: {
          minHeight: 0,
          transitionTimingFunction: 'ease-out',
          transitionDuration: `${refDuration}s`,
        },
        transitionProperty: 'height',
      }}
    >
      {styles => <div style={styles} className={classes.background} />}
    </Transition>
  );
}

export interface DropdownRootProps {
  offsetTop: number;
}

export function DropdownRoot({ offsetTop }: DropdownRootProps) {
  const { classes } = useStyles({ offsetTop });
  const { options, cachedId, getOptionById, targetId } = useContext(DropdownContext);
  const cachedOption = useMemo(() => getOptionById(cachedId), [cachedId, getOptionById]);
  let [width, height, x] = [0, 0, 0];

  if (cachedOption) {
    const { optionCenterX, contentDimensions } = cachedOption;
    width = contentDimensions?.width || 0;
    height = contentDimensions?.height || 0;
    x = optionCenterX - width / 2;
  }

  const [hovering, setHovering] = useState(false);
  const isActive = targetId !== undefined || hovering;

  /** First interaction */
  const [hasInteracted, setHasInteracted] = useState(false);
  const isFirstInteraction = isActive && !hasInteracted;

  if (isFirstInteraction) {
    setTimeout(() => {
      if (!hasInteracted) {
        setHasInteracted(true);
      }
    }, 15);
  }

  /** Active timeout */
  useEffect(() => {
    if (isActive) {
      return () => undefined;
    }
    const timeout = setTimeout(() => setHasInteracted(false), refDuration * 1000 * 0.9);
    return () => clearTimeout(timeout);
  }, [isActive]);

  return (
    <div className={classes.root}>
      <Transition
        mounted={isActive}
        transition={{
          in: { opacity: 1, transform: 'rotateX(15)' },
          out: { opacity: 0, transform: 'rotateX(-15)' },
          transitionProperty: 'opacity, transform',
          common: { transitionDuration: `${refDuration}s`, transitionDelay: '0.05s' },
        }}
      >
        {styles => (
          <div style={styles} className={classes.rootContainer}>
            <Transition
              mounted
              transition={{
                in: { transform: `translateX(${x}px)`, width, height, pointerEvents: 'unset' },
                out: {},
                transitionProperty: 'transform, width, height',
                common: {
                  transitionTimingFunction: 'ease-out',
                  transitionDuration: [
                    isFirstInteraction ? '0s' : `${refDuration}s`,
                    isFirstInteraction ? '0s' : `${refDuration * 0.93}s`,
                    isFirstInteraction ? '0s' : `${refDuration * 0.93}s`,
                  ].join(','),
                },
              }}
            >
              {containerStyles => (
                <div
                  style={containerStyles}
                  className={classes.dropdownContainer}
                  onMouseOver={() => setHovering(true)}
                  onMouseLeave={() => setHovering(false)}
                >
                  <div className={classes.background} />
                  <Transition
                    mounted
                    transition={{
                      in: { transform: `translateX(${-x}px)` },
                      out: {},
                      transitionProperty: 'transform',
                      common: {
                        transitionDuration: isFirstInteraction ? '0s' : undefined,
                      },
                    }}
                  >
                    {sectionStyles => (
                      <div style={sectionStyles}>
                        {options.map(item => (
                          <DropdownSection key={item.id} option={item} />
                        ))}
                      </div>
                    )}
                  </Transition>
                </div>
              )}
            </Transition>
            <DropdownArrow isFirstInteraction={isFirstInteraction} />
          </div>
        )}
      </Transition>
    </div>
  );
}
