import React from 'react';
import Chopper from './Chopper';
import Default from './Default';
import Jet from './Jet';
import Plane from './Plane';

export interface AircraftIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'chopper' | 'default' | 'jet' | 'plane';
  size?: number;
}

function AircraftIcon({ variant = 'chopper', size, ...props }: AircraftIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    case 'default':
      return <Default {...dimensions} {...props} />;
    case 'jet':
      return <Jet {...dimensions} {...props} />;
    case 'plane':
      return <Plane {...dimensions} {...props} />;
    default:
      return <Chopper {...dimensions} {...props} />;
  }
}

export default React.memo(AircraftIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
