import React from 'react';
import useStyles from './Section.styles';

interface SectionProps extends React.ComponentPropsWithoutRef<'section'> {}

export function Section({ className, ...others }: SectionProps) {
  const { classes, cx } = useStyles();

  return <section className={cx(classes.root, className)} {...others} />;
}
