import React from 'react';
import Default from './Default';
import Failed from './Failed';

export interface PassFailIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'default' | 'failed';
  size?: number;
}

function PassFailIcon({ variant = 'default', size, ...props }: PassFailIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    case 'failed':
      return <Failed {...dimensions} {...props} />;
    default:
      return <Default {...dimensions} {...props} />;
  }
}

export default React.memo(PassFailIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
