import { createStyles } from '@aviationexam/core';

function getHighlightStyles(position: 'left' | 'right') {
  const shared = {
    content: '""',
    position: 'absolute' as const,
    zIndex: 0,
    borderRadius: '100%',
  };
  if (position === 'right') {
    return {
      ...shared,
      right: -193,
      bottom: -186,
      background: 'linear-gradient(90deg, #FFFFFF -58.46%, rgba(255, 255, 255, 0) 64.74%)',
      width: 375,
      height: 375,
    };
  }
  return {
    ...shared,
    left: -293,
    top: -286,
    background: 'linear-gradient(238.19deg, #FFFFFF -3.46%, rgba(255, 255, 255, 0) 55.18%)',
    width: 497,
    height: 497,
  };
}

interface FeaturesListStyles {
  highlight: 'left' | 'right';
}

export default createStyles((theme, { highlight }: FeaturesListStyles) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.colors.gray[1],
    borderRadius: theme.radius.sm,
    boxShadow: theme.shadows.xl,
    overflow: 'hidden',
    padding: theme.spacing.xl,
    '& *': {
      zIndex: 1,
    },
    '&:after': {
      ...getHighlightStyles(highlight),
    },
  },
  featuresWrapper: {
    width: '100%',
  },
  featureRow: {
    padding: theme.spacing.xs,
  },
  borderTop: {
    borderTop: `1px solid ${theme.colors.gray[3]}`,
  },
  grow: {
    flexGrow: 1,
  },
  dimmedColor: {
    color: theme.colors.gray[6],
  },
  noShow: {
    opacity: 0,
  },
}));
