import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from './use-locale';

export function usePublicTranslation(ns?: string | string[]) {
  const locale = useLocale();
  const { i18n, ...others } = useTranslation(ns);

  useEffect(() => {
    if (i18n.language !== locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale, i18n]);

  return {
    i18n,
    ...others,
    t: i18n.getFixedT(locale, ns),
  };
}
