import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 71 57"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={31.5} cy={31.5} r={25.5} fill="#06C" fillOpacity={0.15} />
      <path
        d="M47.489 29.023c.058.292.383.704.383.704 1.046 1.804 8.998 6.44 16.617 6.743 0 0-2.293 1.85-2.386 1.84-6.75-.764-16.1-2.97-26.982-6.72-3.361-1.16-8.735-3.214-14.684-5.712C9.604 20.919 6.472 20.102 0 18.82l2.998-2.01s8.71 4.045 19.919 3.359c7.937-.486 15.56-8.28 15.56-8.28 17.076-3.17 33.118.499 32.113.676-12.458 2.199-16.465 5.73-22.72 15.163 0 0-.513.63-.381 1.295Z"
        fill="#06C"
      />
      <path
        d="M56.15 9.778C51.98 3.722 45.3 0 37.408 0 26.04 0 16.617 8.179 14.757 19.014l.84.136C17.39 8.72 26.462.849 37.406.849c7.39 0 13.632 3.355 17.685 8.918l1.059.011Z"
        fill="#06C"
      />
      <path
        d="M56.15 9.778C51.98 3.722 45.3 0 37.408 0 26.04 0 16.617 8.179 14.757 19.014l.84.136C17.39 8.72 26.462.849 37.406.849c7.39 0 13.632 3.355 17.685 8.918l1.059.011ZM14.461 24.021l.875.472c.669 11.633 10.272 20.659 22.072 20.659 6.685 0 12.487-2.827 16.548-7.51l.935.214C50.672 42.91 44.507 46 37.408 46c-12.417 0-22.498-9.673-22.947-21.98Z"
        fill="#06C"
      />
      <path
        d="m14.461 24.021.875.472c.669 11.633 10.272 20.659 22.072 20.659 6.685 0 12.487-2.827 16.548-7.51l.935.214C50.672 42.91 44.507 46 37.408 46c-12.417 0-22.498-9.673-22.947-21.98ZM56.62 34.033A22.047 22.047 0 0 0 59.56 23c0-2.042-.212-3.637-.73-5.514l.746-.485c.584 2.026.832 3.784.832 5.999 0 4.136-1.091 8.016-3.002 11.37l-.786-.337Z"
        fill="#06C"
      />
      <path
        d="M56.62 34.033A22.047 22.047 0 0 0 59.56 23c0-2.042-.212-3.637-.73-5.514l.746-.485c.584 2.026.832 3.784.832 5.999 0 4.136-1.091 8.016-3.002 11.37l-.786-.337Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
