import { useCallback, useContext } from 'react';
import { LocaleContext } from '../Locale.context';
import { Locale } from '../types';

export function useOutsideLinks() {
  const { outsideLinks, locale } = useContext(LocaleContext);
  const localize = useCallback(
    (link: Partial<Record<Locale, string>>) => link[locale] ?? '',
    [outsideLinks, locale]
  );
  return {
    links: outsideLinks,
    localize,
  };
}
