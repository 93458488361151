import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';
import { Button, Col, Grid, Group, ScrollArea, Text, Transition } from '@aviationexam/core';
import {
  ProjectIcon,
  SitemapIcon,
  StarIcon,
  MailIcon,
  DownloadIcon,
  PenIcon,
  ShieldCheckIcon,
  ListIcon,
} from '@aviationexam/icons';
import { LocalizedLink, useLocale, usePublicTranslation } from 'features/localization';
import {
  ABOUT_PAGE,
  CONTACT_PAGE,
  REVIEWS_PAGE,
  GDPR_PAGE,
  LMS_PAGE,
  ES_PAGE,
  QUESTION_BANKS_EASA_PAGE,
  QUESTION_BANKS_EASA_GERMAN_PAGE,
  QUESTION_BANKS_PPL_PAGE,
  BLOG_PAGE,
  CUSTOMER_REVIEWS_PAGE,
  FEATURES_PAGE,
  SUBSCRIPTION_PAGE,
  BOOKS_PAGE,
  TEXTBOOKS_PAGE,
  DOWNLOADS_PAGE,
  TAC_PAGE,
  REGISTER_PAGE,
  APP_PAGE,
} from 'config/routes';
import {
  CountryFlagIcon,
  EducationIcon,
  ClassroomIcon,
  StampIcon,
  DaecIcon,
  CloudIcon,
  BookIcon,
  TextbookIcon,
} from 'assets/icons';
import useStyles from './DrawerNavigation.styles';
import { LinkContent } from '../LinkContent/LinkContent';

export interface DrawerNavigationProps {
  opened: boolean;
}

export function DrawerNavigation({ opened }: DrawerNavigationProps) {
  const { t } = usePublicTranslation();
  const { classes, cx } = useStyles();
  const locale = useLocale();
  const location = useLocation();
  return (
    <Transition mounted={opened} transition="slide-left">
      {styles => (
        <nav style={styles} className={classes.root}>
          <ScrollArea className={classes.scrollArea}>
            <div className={classes.scrollbarsContainer}>
              <Grid>
                <Col span={12}>
                  <Group position="center" spacing="xs">
                    <GatsbyLink
                      to="/"
                      className={cx(classes.link, {
                        [classes.linkActive]: locale === 'en',
                      })}
                    >
                      EN
                    </GatsbyLink>
                    <GatsbyLink
                      to="/de"
                      className={cx(classes.link, {
                        [classes.linkActive]: locale === 'de',
                      })}
                    >
                      DE
                    </GatsbyLink>
                  </Group>
                </Col>
                <Col span={12}>
                  <Group position="center" spacing="xs">
                    <a href={APP_PAGE} target="_blank" rel="noreferrer" className={classes.link}>
                      {t('navigation.login')}
                    </a>
                    <Button<'a'>
                      size="xs"
                      uppercase
                      component="a"
                      href={REGISTER_PAGE(location.href)}
                    >
                      {t('navigation.signUp')}
                    </Button>
                  </Group>
                </Col>
                <Col span={12}>
                  <Group direction="column" grow spacing="xs">
                    <Text color="dimmed" weight={600} transform="uppercase">
                      {t('navigation.company.title')}
                    </Text>
                    <LocalizedLink to={ABOUT_PAGE}>
                      <LinkContent
                        icon={<SitemapIcon size={20} variant="solid" />}
                        title={t('navigation.company.team.title')}
                        description={t('navigation.company.team.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={CONTACT_PAGE}>
                      <LinkContent
                        icon={<MailIcon size={20} />}
                        title={t('navigation.company.contact.title')}
                        description={t('navigation.company.contact.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={REVIEWS_PAGE}>
                      <LinkContent
                        icon={<StarIcon size={20} />}
                        title={t('navigation.company.reviews.title')}
                        description={t('navigation.company.reviews.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={CUSTOMER_REVIEWS_PAGE}>
                      <LinkContent
                        icon={<ProjectIcon size={20} />}
                        title={t('navigation.company.customerReviews.title')}
                        description={t('navigation.company.customerReviews.description')}
                      />
                    </LocalizedLink>

                    <Text color="dimmed" weight={600} transform="uppercase">
                      {t('navigation.solutions.title')}
                    </Text>
                    <LocalizedLink to={FEATURES_PAGE}>
                      <LinkContent
                        icon={<EducationIcon size={20} />}
                        title={t('navigation.solutions.students.title')}
                        description={t('navigation.solutions.students.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={LMS_PAGE}>
                      <LinkContent
                        icon={<ClassroomIcon size={20} />}
                        title={t('navigation.solutions.schools.title')}
                        description={t('navigation.solutions.schools.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={ES_PAGE}>
                      <LinkContent
                        icon={<StampIcon size={20} />}
                        title={t('navigation.solutions.authorities.title')}
                        description={t('navigation.solutions.authorities.description')}
                      />
                    </LocalizedLink>

                    <Text color="dimmed" weight={600} transform="uppercase">
                      {t('navigation.products.title')}
                    </Text>
                    <LocalizedLink to={SUBSCRIPTION_PAGE}>
                      <LinkContent
                        icon={<CloudIcon size={20} />}
                        title={t('navigation.products.subscription.title')}
                        description={t('navigation.products.subscription.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={BOOKS_PAGE}>
                      <LinkContent
                        icon={<BookIcon size={20} />}
                        title={t('navigation.products.books.title')}
                        description={t('navigation.products.books.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={TEXTBOOKS_PAGE}>
                      <LinkContent
                        icon={<TextbookIcon size={20} />}
                        title={t('navigation.products.textbooks.title')}
                        description={t('navigation.products.textbooks.description')}
                      />
                    </LocalizedLink>

                    <Text color="dimmed" weight={600} transform="uppercase">
                      {t('navigation.questionBanks.title')}
                    </Text>
                    <LocalizedLink to={QUESTION_BANKS_EASA_PAGE}>
                      <LinkContent
                        icon={
                          <div className={classes.icon}>
                            <CountryFlagIcon variant="uk" size={20} />
                          </div>
                        }
                        title={t('navigation.questionBanks.easa.title')}
                        description={t('navigation.questionBanks.easa.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={QUESTION_BANKS_EASA_GERMAN_PAGE}>
                      <LinkContent
                        icon={
                          <div className={classes.icon}>
                            <CountryFlagIcon variant="de" size={20} />
                          </div>
                        }
                        title={t('navigation.questionBanks.easaGerman.title')}
                        description={t('navigation.questionBanks.easaGerman.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={QUESTION_BANKS_PPL_PAGE}>
                      <LinkContent
                        icon={<DaecIcon size={20} />}
                        title={t('navigation.questionBanks.ppl.title')}
                        description={t('navigation.questionBanks.ppl.description')}
                      />
                    </LocalizedLink>

                    <Text
                      color="dimmed"
                      weight={600}
                      transform="uppercase"
                      className={classes.backgroundLink}
                    >
                      {t('navigation.miscellaneous')}
                    </Text>
                    <LocalizedLink to={DOWNLOADS_PAGE} className={classes.backgroundLink}>
                      <LinkContent
                        icon={<DownloadIcon size={20} />}
                        title={t('navigation.products.downloads.title')}
                        description={t('navigation.products.downloads.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={BLOG_PAGE} className={classes.backgroundLink}>
                      <LinkContent
                        icon={<PenIcon size={20} />}
                        title={t('navigation.blog.title')}
                        description={t('navigation.blog.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={GDPR_PAGE} className={classes.backgroundLink}>
                      <LinkContent
                        icon={<ShieldCheckIcon size={20} />}
                        title={t('navigation.gdpr.title')}
                        description={t('navigation.gdpr.description')}
                      />
                    </LocalizedLink>
                    <LocalizedLink to={TAC_PAGE} className={classes.backgroundLink}>
                      <LinkContent
                        icon={<ListIcon size={20} />}
                        title={t('navigation.termsAndConditions.title')}
                        description={t('navigation.termsAndConditions.description')}
                      />
                    </LocalizedLink>
                  </Group>
                </Col>
              </Grid>
            </div>
          </ScrollArea>
        </nav>
      )}
    </Transition>
  );
}
