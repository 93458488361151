import { createStyles } from '@aviationexam/core';
import { HEADER_HEIGHT } from 'components/Layout/Layout.styles';

export default createStyles(theme => ({
  root: {
    position: 'fixed',
    top: HEADER_HEIGHT,
    bottom: 0,
    left: 0,
    right: 0,
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    backgroundColor: theme.white,
    width: '100%',
  },
  scrollArea: {
    height: '100%',
  },
  scrollbarsContainer: {
    padding: theme.spacing.sm,
  },
  backgroundLink: {
    margin: -8,
    padding: 13,
    backgroundColor: theme.colors.gray[2],
  },
  link: {
    ...theme.fn.focusStyles(),
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    padding: theme.spacing.xs,
    fontWeight: 600,
    letterSpacing: 1,
    '&:hover, &:focus': {
      color: theme.colors.gray[6],
    },
  },
  linkActive: {
    color: theme.colors.gray[8],
    fontWeight: 700,
  },
  icon: {
    width: 20,
    height: 20,
    borderRadius: 9999,
    overflow: 'hidden',
  },
}));
