import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 0a1 1 0 0 0-1 1v4.832a1 1 0 0 0 0 .326v29.674a1 1 0 0 0 0 .326V41a1 1 0 0 0 1 1h46a1 1 0 0 0 1-1v-4.832a.997.997 0 0 0 0-.326V6.168a.997.997 0 0 0 0-.326V1a1 1 0 0 0-1-1H2Zm1 2h3v3H3V2Zm5 0h4v3H8V2Zm6 0h4v3h-4V2Zm6 0h4v3h-4V2Zm6 0h4v3h-4V2Zm6 0h4v3h-4V2Zm6 0h4v3h-4V2Zm6 0h3v3h-3V2ZM3 7h44v28H3V7Zm17.941 7A1 1 0 0 0 20 15v12a1 1 0 0 0 1.513.857l10-6a1 1 0 0 0 0-1.714l-10-6a1 1 0 0 0-.572-.143ZM22 16.766 29.056 21 22 25.234v-8.468ZM3 37h3v3H3v-3Zm5 0h4v3H8v-3Zm6 0h4v3h-4v-3Zm6 0h4v3h-4v-3Zm6 0h4v3h-4v-3Zm6 0h4v3h-4v-3Zm6 0h4v3h-4v-3Zm6 0h3v3h-3v-3Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
