import React, { useState } from 'react';
import { DEFAULT_LOCALE } from './constants';
import { LocaleContext } from './Locale.context';
import { outsideLinks } from './outside-links';
import { Locale } from './types';

export interface LocaleProviderProps {
  locale?: Locale;
  children: React.ReactNode;
}

export function LocaleProvider({ locale = DEFAULT_LOCALE, children }: LocaleProviderProps) {
  const [routeAlternatives, setRouteAlternatives] = useState<Record<Locale, string>>({
    en: '/',
    de: '/de',
  });

  return (
    <LocaleContext.Provider
      value={{
        locale,
        routeAlternatives,
        setRouteAlternatives,
        outsideLinks,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}
