import commonEn from './common.en.json';
import commonDe from './common.de.json';

const resources = {
  en: {
    common: commonEn,
  },
  de: {
    common: commonDe,
  },
};

export default resources;
