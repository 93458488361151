import React from 'react';
import useStyles from './Floating.styles';

export interface FloatingProps extends React.ComponentPropsWithoutRef<'div'> {
  reverse?: boolean;
}

export function Floating({ className, reverse = false, ...others }: FloatingProps) {
  const { classes, cx } = useStyles({ reverse });
  return <div className={cx(classes.root, className)} {...others} />;
}
