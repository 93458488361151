import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m8 .084-.81 2.104-1.176 3.05-5.516.297 4.287 3.483-1.42 5.335 4.635-3 4.637 2.999-1.422-5.334 4.287-3.483-5.514-.297L8 .084Zm0 4.83.771 1.998 2.137.115-1.662 1.35.55 2.068L8 9.283l-1.797 1.164.55-2.07-1.661-1.35 2.138-.115L8 4.914Zm12 1.104v1.964h30V6.018H20ZM8 17.584l-.81 2.104-1.176 3.05-5.516.297 4.287 3.483-1.42 5.335 4.635-3 4.637 2.999-1.422-5.334 4.287-3.483-5.514-.297L8 17.584Zm0 4.83.771 1.998 2.137.115-1.662 1.35.55 2.068L8 26.783l-1.797 1.164.55-2.07-1.661-1.35 2.138-.115.77-1.998Zm12 1.604v1.964h30v-1.964H20ZM8 34.584l-.81 2.103-1.176 3.051-5.516.297 4.287 3.483-1.42 5.335 4.635-3 4.637 2.999-1.422-5.334 4.287-3.483-5.514-.297L8 34.584Zm0 4.83.771 1.998 2.137.115-1.662 1.35.55 2.068L8 43.783l-1.797 1.164.55-2.07-1.661-1.35 2.138-.115.77-1.998Zm12 2.604v1.964h30v-1.964H20Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
