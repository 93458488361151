import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 46"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.967.96a.96.96 0 0 0-.56.204L4.246 16.162l-2.917 2.282a.96.96 0 1 0 1.185 1.511l1.327-1.039V44.16a.96.96 0 0 0 .96.96h38.4a.96.96 0 0 0 .96-.96V18.916l1.328 1.04a.96.96 0 1 0 1.185-1.512l-2.865-2.24-19.215-15.04a.96.96 0 0 0-.626-.204ZM24 3.138l18.24 14.275V43.2H5.76V17.413L24 3.138ZM11.52 16.32c-.501 0-1.012.183-1.374.545-.362.362-.546.873-.546 1.375v6.72c0 .5.184 1.012.546 1.374.362.362.873.546 1.374.546h24.96c.502 0 1.013-.184 1.375-.546.362-.362.545-.873.545-1.374v-6.72c0-.502-.183-1.013-.545-1.375-.362-.362-.873-.545-1.375-.545H11.52Zm0 1.92h24.96v6.72H11.52v-6.72Zm4.32 1.92c-.792 0-1.44.648-1.44 1.44 0 .792.648 1.44 1.44 1.44.792 0 1.44-.648 1.44-1.44 0-.792-.648-1.44-1.44-1.44Zm-4.32 9.6c-.501 0-1.012.183-1.374.545-.362.362-.546.873-.546 1.375v6.72c0 .5.184 1.012.546 1.374.362.362.873.546 1.374.546h24.96c.502 0 1.013-.184 1.375-.546.362-.362.545-.873.545-1.374v-6.72c0-.502-.183-1.013-.545-1.375-.362-.362-.873-.545-1.375-.545H11.52Zm0 1.92h24.96v6.72H11.52v-6.72Zm4.32 1.92c-.792 0-1.44.648-1.44 1.44 0 .792.648 1.44 1.44 1.44.792 0 1.44-.648 1.44-1.44 0-.792-.648-1.44-1.44-1.44Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
