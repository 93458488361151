import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={25} cy={25} r={25} fill="#32D74B" fillOpacity={0.15} />
      <path
        d="M7 1C4.25 1 2 3.25 2 6v22c0 2.75 2.25 5 5 5h36c2.75 0 5-2.25 5-5V6c0-2.75-2.25-5-5-5H7Zm0 2h36c1.668 0 3 1.332 3 3v2H4V6c0-1.668 1.332-3 3-3ZM4 13h42v15c0 1.668-1.332 3-3 3H7c-1.668 0-3-1.332-3-3V13Zm5 3v2h16v-2H9Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
