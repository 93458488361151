import React, { useState, useCallback, useEffect } from 'react';
import { DimensionObject } from 'hooks';

export interface Option {
  id: number;
  optionDimensions: DimensionObject;
  optionCenterX: number;
  WrappedContent(props: React.ComponentPropsWithoutRef<'div'>): JSX.Element;
  contentDimensions?: DimensionObject;
  backgroundHeight: number;
}

interface DropdownContextState {
  registerOption: (option: Option) => void;
  updateOptionProps: (id: Option['id'], props: Partial<Option>) => void;
  getOptionById: (id: Option['id'] | undefined) => Option | undefined;
  deleteOptionById: (id: Option['id']) => void;
  options: Option[];
  targetId: number | undefined;
  setTargetId: (number: number | undefined) => void;
  cachedId: number | undefined;
  setCachedId: (number: number) => void;
}
export const DropdownContext = React.createContext<DropdownContextState>({
  registerOption: () => undefined,
  updateOptionProps: () => undefined,
  getOptionById: () => undefined,
  deleteOptionById: () => undefined,
  options: [],
  targetId: undefined,
  setTargetId: () => undefined,
  cachedId: undefined,
  setCachedId: () => undefined,
});

export interface DropdownProviderProps {
  children: React.ReactNode;
}

export function DropdownProvider({ children }: DropdownProviderProps) {
  const [options, setOptions] = useState<Option[]>([]);
  const [targetId, setTargetId] = useState<number | undefined>();
  const [cachedId, setCachedId] = useState<number | undefined>();

  const registerOption = useCallback(
    ({ id, optionDimensions, optionCenterX, WrappedContent, backgroundHeight }: Option) => {
      setOptions(items => [
        ...items,
        { id, optionDimensions, optionCenterX, WrappedContent, backgroundHeight },
      ]);
    },
    [setOptions]
  );

  const updateOptionProps = useCallback(
    (optionId: Option['id'], props: Partial<Option>) => {
      setOptions(items =>
        items.map(item => {
          if (item.id === optionId) {
            return { ...item, ...props };
          }
          return item;
        })
      );
    },
    [setOptions]
  );

  const getOptionById = useCallback(
    (id: Option['id'] | undefined) => options.find(item => item.id === id),
    [options]
  );

  const deleteOptionById = useCallback(
    (id: Option['id']) => {
      setOptions(items => items.filter(item => item.id !== id));
    },
    [setOptions]
  );

  useEffect(() => {
    if (targetId !== undefined) {
      setCachedId(targetId);
    }
  }, [targetId]);

  return (
    <DropdownContext.Provider
      value={{
        registerOption,
        updateOptionProps,
        getOptionById,
        deleteOptionById,
        options,
        targetId,
        setTargetId,
        cachedId,
        setCachedId,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
}
