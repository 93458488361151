import React, { useContext, useEffect } from 'react';
import { useTranslatedRoute } from './use-translated-route';
import { LocaleContext } from '../Locale.context';
import { Locale } from '../types';

export function useAlternateLinks(route: string, currentLocale: Locale) {
  const { setRouteAlternatives } = useContext(LocaleContext);
  const enRoute = useTranslatedRoute('en');
  const deRoute = useTranslatedRoute('de');

  useEffect(() => {
    setRouteAlternatives({
      en: enRoute(route),
      de: deRoute(route),
    });
  }, [route, enRoute, deRoute, setRouteAlternatives]);

  return {
    alternateLinks: (
      <link
        rel="alternate"
        hrefLang={currentLocale === 'en' ? 'de' : 'en'}
        href={currentLocale === 'en' ? deRoute(route) : enRoute(route)}
      />
    ),
  };
}
