import React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { Locale } from '../../types';
import { useLocale, useTranslatedRoute } from '../../hooks';

export interface LocalizedLinkProps extends GatsbyLinkProps<Record<string, unknown>> {
  /** Forces locale on hrefLang */
  locale?: Locale;
}

export function LocalizedLink({ to, children, locale: localeProp, ...others }: LocalizedLinkProps) {
  const localeContext = useLocale();
  const locale = localeProp ?? localeContext;
  const { ref, style, ...rest } = others;
  const route = useTranslatedRoute();

  return (
    <GatsbyLink
      ref={ref as any}
      to={route(to)}
      hrefLang={locale}
      style={{ textDecoration: 'none', ...style }}
      {...rest}
    >
      {children}
    </GatsbyLink>
  );
}
