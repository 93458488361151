import React from 'react';
import De from './De';
import Eu from './Eu';
import Uk from './Uk';

export interface CountryFlagIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'de' | 'eu' | 'uk';
  size?: number;
}

function CountryFlagIcon({ variant = 'de', size, ...props }: CountryFlagIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    case 'eu':
      return <Eu {...dimensions} {...props} />;
    case 'uk':
      return <Uk {...dimensions} {...props} />;
    default:
      return <De {...dimensions} {...props} />;
  }
}

export default React.memo(CountryFlagIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
