import { Button, Group, Text } from '@aviationexam/core';
import React from 'react';
import useStyles from './ActionBar.styles';

interface ActionBarProps extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Name displayed in left corner
   */
  title: string;
  /**
   * Action label
   */
  label: string;
  /**
   * Specify href if action should lead outside of the website
   */
  href?: string;
  /**
   * Specify onClick event if action should be triggered by button
   */
  onClick?(): void;
}

export function ActionBar({ title, label, href, onClick, className, ...others }: ActionBarProps) {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(classes.root, className)} {...others}>
      <Group position="apart" noWrap>
        <Text weight={600} className={classes.text} lineClamp={1}>
          {title}
        </Text>
        {href ? (
          <Button<'a'>
            component="a"
            target="_blank"
            href={href}
            rel="noreferrer"
            variant="white"
            className={classes.button}
          >
            {label}
          </Button>
        ) : (
          <Button variant="white" onClick={onClick} className={classes.button}>
            {label}
          </Button>
        )}
      </Group>
    </div>
  );
}
