import React from 'react';
import { LocalizedLink, LocalizedLinkProps, usePublicTranslation } from 'features/localization';
import { HoverCard, HoverCardProps } from 'components';

export interface LinkHoverCardProps
  extends HoverCardProps,
    Omit<LocalizedLinkProps, 'title' | 'color'> {
  /** Link element */
  to: string;
}

export function LinkHoverCard({
  icon,
  title,
  description,
  to,
  backgroundColor,
  color,
  ...others
}: LinkHoverCardProps) {
  const { t } = usePublicTranslation();
  return (
    <LocalizedLink
      to={to}
      style={{ display: 'block', textDecoration: 'none' }}
      aria-label={t('actions.scrollDownTo', { where: title })}
      {...others}
    >
      <HoverCard
        icon={icon}
        title={title}
        description={description}
        backgroundColor={backgroundColor}
        color={color}
      />
    </LocalizedLink>
  );
}
