import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.102 3A2.107 2.107 0 0 0 3 5.102v39.8C3 46.055 3.945 47 5.102 47h11.8c.414 0 .801-.11 1.13-.3.312.187.675.3 1.066.3h11.168c-.45-.625-.84-1.293-1.164-2H19.098c-.047 0-.098-.05-.098-.098V14.098c0-.047.05-.098.098-.098h11.804c.047 0 .098.05.098.098v17.98a12.248 12.248 0 0 1 2-1.812V5.102c0-.051.05-.102.098-.102h11.804c.047 0 .098.05.098.102v24c.707.324 1.375.714 2 1.164V5.102C47 3.942 46.059 3 44.902 3H33.098A2.101 2.101 0 0 0 31 5.102v6.91c-.035-.004-.066-.012-.098-.012H19.098c-.032 0-.063.008-.098.012v-6.91A2.106 2.106 0 0 0 16.902 3h-11.8Zm0 2h11.8c.043 0 .098.055.098.102V45c0-.168-.055 0-.098 0h-11.8C5.055 45 5 44.945 5 44.902v-39.8C5 5.055 5.055 5 5.102 5ZM8 11a1.006 1.006 0 0 0-.879.496 1.01 1.01 0 0 0 0 1.008c.184.312.52.5.879.496h6c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H8Zm28 0a1.006 1.006 0 0 0-.879.496 1.01 1.01 0 0 0 0 1.008c.184.312.52.5.879.496h6c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496h-6Zm-14 9a1.006 1.006 0 0 0-.879.496 1.01 1.01 0 0 0 0 1.008c.184.312.52.5.879.496h6c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496h-6Zm18 10c-5.512 0-10 4.488-10 10s4.488 10 10 10 10-4.488 10-10-4.488-10-10-10Zm0 2c4.43 0 8 3.57 8 8s-3.57 8-8 8-8-3.57-8-8 3.57-8 8-8ZM8 34a1.006 1.006 0 0 0-.879.496 1.01 1.01 0 0 0 0 1.008c.184.312.52.5.879.496h6c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H8Zm36.238 1.348-5.39 6.293-3.25-2.442-1.196 1.602 4.75 3.558 6.61-7.707-1.524-1.304ZM8 38a1.006 1.006 0 0 0-.879.496 1.01 1.01 0 0 0 0 1.008c.184.312.52.5.879.496h6c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H8Zm14 1c-.55 0-1 .45-1 1s.45 1 1 1h6.05c-.027-.332-.05-.66-.05-1 0-.34.023-.668.05-1H22Z"
        fill="#FF5236"
      />
    </svg>
  );
}

export default SvgDefault;
