import React from 'react';
import { usePublicTranslation } from 'features/localization';
import Animated from '../../images/memojis/tom_animated.gif';
import Static from '../../images/memojis/tom_static.gif';
import { Memoji } from './Memoji';

export function TomMemoji() {
  const { t } = usePublicTranslation();
  return (
    <Memoji
      src={Static}
      animatedSrc={Animated}
      name={t('team.tom.name')}
      title={t('team.tom.title')}
      color="rgba(0, 102, 204, 0.15)"
      backdrop="right"
    />
  );
}
