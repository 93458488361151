import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#default_svg__a)">
        <path
          d="M24.879 4.3c-3.685.084-6.379 1.21-8.006 3.348-1.926 2.534-2.287 6.417-1.076 11.547-.413.531-.785 1.381-.688 2.541.296 2.16 1.117 3.05 1.786 3.414.308 1.75 1.154 3.675 2.005 4.596v3.563c-.61 1.375-2.6 2.17-4.89 3.088-3.864 1.546-8.674 3.47-9.008 9.548L4.943 47h21.46l.568-2H7.12c.73-3.985 4.232-5.387 7.631-6.746 2.013-.806 3.94-1.581 5.139-2.879.318.295.698.545 1.101.752 1.079.554 2.465.873 4.008.873 1.555 0 2.946-.35 4.018-.916.377-.199.73-.43 1.035-.695.627.659 1.437 1.184 2.353 1.646l1.492-1.504c-1.373-.623-2.457-1.291-2.9-2.234v-3.55c.846-.917 1.691-2.83 2.002-4.571.712-.35 1.59-1.24 1.795-3.492.095-1.126-.215-1.955-.64-2.489.848-2.728 1.593-7.402-.204-10.373-.85-1.408-2.152-2.293-3.869-2.636-.966-1.22-2.781-1.885-5.203-1.885Zm.023 1.999c1.891 0 3.253.477 3.737 1.305l.244.417.476.069c1.332.188 2.274.765 2.881 1.767 1.448 2.395.687 6.877-.181 9.305l-.317.824.793.397c.09.063.33.408.27 1.125-.135 1.48-.605 1.874-.705 1.892h-.91l-.087.907c-.18 1.896-1.237 3.853-1.6 4.125l-.503.287V33.5c0-.011-.188.432-.916.816-.729.385-1.839.684-3.084.684-1.257 0-2.372-.282-3.094-.652-.721-.371-.906-.762-.906-.848a1 1 0 0 0-.1-.445V29.3l.022-.569-.504-.289c-.381-.284-1.44-2.242-1.621-4.138l-.014-.897h-.91c-.142-.055-.587-.528-.775-1.89-.07-.84.358-1.186.357-1.186l.59-.393-.174-.685c-1.213-4.783-1.012-8.28.596-10.395 1.239-1.63 3.412-2.492 6.435-2.56ZM45.9 28c-1 0-1.9.4-2.7 1.1l-.2.1-.1.1-.3.3-.7.7-.2.2L30 42.3c-.1.1-.2.3-.3.4l-1.7 6c-.2.5.1 1.1.7 1.2.2.1.4.1.5 0l6-1.6c.2 0 .3-.1.5-.3l12.8-12.8.3-.2c1.6-1.6 1.6-4.2 0-5.8-.8-.9-1.9-1.2-2.9-1.2Zm0 2c.5 0 1.1.2 1.5.6.8.8.8 2 .1 2.8l-2.9-2.9c.4-.3.8-.5 1.3-.5Zm-2.8 1.9 3 3-10.9 10.9-3-2.9 10.9-11ZM31.2 44.8l2.1 2.1-2.9.8.8-2.9Z"
          fill="#FF5236"
        />
      </g>
      <defs>
        <clipPath id="default_svg__a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefault;
