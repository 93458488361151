import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 82 93"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#default_svg__a)">
        <path
          d="M42.262 65.473c-3.392.017-5.482.85-7.242 1.627l-.302.135c-1.553.688-2.703 1.198-4.503 1.18h-.002c-2.056-.02-3.983-1.035-5.833-2.728-1.85-1.695-3.487-3.945-4.946-6.161-3.99-6.077-6.185-12.663-6.772-18.645-.59-6.007.45-11.27 2.755-14.812 3.378-5.2 8.638-8.13 13.4-8.13 2.448 0 4.455.666 6.548 1.41l.355.128c1.944.695 4.072 1.457 6.486 1.457 2.279 0 4.168-.719 5.923-1.387l.553-.209c1.94-.727 3.985-1.405 6.872-1.405 3.78 0 7.88 1.854 11.115 5.234-10.752 7.389-8.85 23.463 2.335 28.922-1.224 2.67-2.026 4.19-3.788 6.874-1.465 2.229-3.164 4.627-5.083 6.466-1.933 1.853-3.903 2.96-5.912 2.974h-.003c-1.77.016-2.88-.49-4.386-1.178l-.348-.158c-1.743-.79-3.816-1.622-7.222-1.594Zm0 0h.002l.008 1.5-.013-1.5h.003Zm7.317-54.972-.001.002c-1.95 2.511-5.118 4.463-8.237 4.825-.088-3.064 1.405-6.23 3.31-8.55 1.986-2.404 5.202-4.33 8.146-4.98.02 3.199-1.37 6.33-3.218 8.703Z"
          stroke="#FF5236"
          strokeWidth={3}
        />
      </g>
      <defs>
        <filter
          id="default_svg__a"
          x={0.983}
          y={0.085}
          width={80.034}
          height={92.369}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.767} />
          <feGaussianBlur stdDeviation={1.107} />
          <feColorMatrix values="0 0 0 0 0.784314 0 0 0 0 0.231373 0 0 0 0 0.145098 0 0 0 0.123704 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1736_10462" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={6.65} />
          <feGaussianBlur stdDeviation={2.66} />
          <feColorMatrix values="0 0 0 0 0.784314 0 0 0 0 0.231373 0 0 0 0 0.145098 0 0 0 0.177713 0" />
          <feBlend in2="effect1_dropShadow_1736_10462" result="effect2_dropShadow_1736_10462" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={12.522} />
          <feGaussianBlur stdDeviation={5.009} />
          <feColorMatrix values="0 0 0 0 0.784314 0 0 0 0 0.231373 0 0 0 0 0.145098 0 0 0 0.22 0" />
          <feBlend in2="effect2_dropShadow_1736_10462" result="effect3_dropShadow_1736_10462" />
          <feBlend in="SourceGraphic" in2="effect3_dropShadow_1736_10462" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default SvgDefault;
