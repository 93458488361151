import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.586 3.068c-.866.01-1.671.097-2.402.233-2.926.54-4.77 1.863-4.77 1.863a1 1 0 0 0-.387.582L7.79 19.517a1 1 0 0 0 1.213 1.2c3.425-.85 8.567-1.233 13.887 1.74a1 1 0 0 0 1.457-.625l3.797-14.885a1 1 0 0 0-.432-1.09c-3.378-2.153-6.527-2.818-9.125-2.789Zm.023 1.99a12.53 12.53 0 0 1 2.393.196c1.517.27 3.21.917 5.008 1.967l-3.317 13a20.569 20.569 0 0 0-12.611-1.717l2.793-11.881c.34-.225 1.508-.957 3.672-1.357a12.382 12.382 0 0 1 2.062-.207Zm12.032 3.137a1 1 0 0 0-.946.752L25.922 23.74a1 1 0 0 0 .385 1.059c6.654 4.781 13.476 3.094 16.314 2.012a1 1 0 0 0 .617-.706l3.735-15.877a1 1 0 0 0-1.56-1.037s-1.467 1.072-4.009 1.537c-2.541.466-6.066.313-10.193-2.37a1 1 0 0 0-.57-.163Zm.681 2.469c4.06 2.255 7.751 2.524 10.442 2.031 1.171-.214 2.008-.575 2.789-.908l-3.13 13.297c-2.603.917-7.907 1.963-13.368-1.617l3.267-12.803ZM13.717 22.121a18.505 18.505 0 0 0-6.371 1.2 1 1 0 0 0-.617.704L3.027 39.772A1 1 0 0 0 3 40v.045a1 1 0 0 0 1.586.81s1.378-1.002 3.787-1.484c2.41-.481 5.757-.424 9.693 1.896a1 1 0 0 0 1.479-.615l3.767-14.771a1 1 0 0 0-.427-1.09c-3.216-2.063-6.426-2.689-9.168-2.67Zm-.037 1.992c2.24-.021 4.848.556 7.49 2.078l-3.266 12.8c-3.879-1.935-7.38-2.09-9.924-1.58-1.07.213-1.818.545-2.544.855l3.109-13.223c1.216-.428 2.963-.909 5.135-.93Zm12.119 2.969a1 1 0 0 0-.92.752l-3.774 14.783a1 1 0 0 0 .397 1.067c4.68 3.27 8.98 3.632 12.058 3.115 3.08-.517 5.026-1.92 5.026-1.92a1 1 0 0 0 .414-.764v-.002l3.178-13.5a1 1 0 0 0-1.215-1.199 20.354 20.354 0 0 1-4.885.607c-2.924 0-6.285-.681-9.707-2.79a1 1 0 0 0-.572-.149Zm.73 2.373c3.346 1.776 6.66 2.567 9.55 2.567 1.446 0 2.624-.254 3.823-.475l-2.795 11.885c-.361.239-1.6 1.012-3.877 1.394-2.52.423-5.982.152-9.998-2.45l3.297-12.921Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
