import React from 'react';
import { createStyles } from '@aviationexam/core';
import { scrollIntoView } from 'utils/scroll';
import { usePublicTranslation } from 'features/localization';
import { HoverCard, HoverCardProps } from 'components';

const useStyles = createStyles(() => ({
  link: {
    display: 'block',
    textDecoration: 'none',
    width: '100%',
    height: '100%',
  },
}));

export interface AnchorHoverCardProps
  extends HoverCardProps,
    Omit<React.ComponentPropsWithoutRef<'a'>, 'title' | 'color'> {
  /** Anchor element */
  to: string;
  /** Whether should behave as normal anchor tag with href or scroll to content */
  scroll?: boolean;
}

export function AnchorHoverCard({
  icon,
  title,
  description,
  to,
  backgroundColor,
  color,
  scroll = true,
  className,
  ...others
}: AnchorHoverCardProps) {
  const { t } = usePublicTranslation();
  const { classes, cx } = useStyles();
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!scroll) {
      return;
    }
    e.preventDefault();
    scrollIntoView(to);
  };
  return (
    <a
      href={scroll ? `#${to}` : to}
      className={cx(classes.link, className)}
      aria-label={t('actions.scrollDownTo', { where: title })}
      {...others}
      onClick={handleScroll}
    >
      <HoverCard
        icon={icon}
        title={title}
        description={description}
        backgroundColor={backgroundColor}
        color={color}
      />
    </a>
  );
}
