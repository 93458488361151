import React from 'react';
import Default from './Default';

export interface ChooseOptionIconProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'default';
  size?: number;
}

function ChooseOptionIcon({ variant = 'default', size, ...props }: ChooseOptionIconProps) {
  const dimensions = typeof size === 'undefined' ? undefined : { width: size, height: size };

  switch (variant) {
    default:
      return <Default {...dimensions} {...props} />;
  }
}

export default React.memo(ChooseOptionIcon);

/**
 * This is generated file. Any changes will be discarded. Check package.json scripts for more information.
 */
