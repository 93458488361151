import { useCallback } from 'react';
import { ROUTE_TRANSLATIONS } from 'config/routes';
import { DEFAULT_LOCALE, UNPREFIXED_ROUTES } from '../constants';
import { Locale } from '../types';
import { useLocale } from './use-locale';

export function useTranslatedRoute(localeProp?: Locale) {
  const localeCtx = useLocale();
  const localeDefault = localeProp ?? localeCtx;

  return useCallback(
    (to: string, overrideLocale?: Locale) => {
      const locale = overrideLocale ?? localeDefault;
      const slug: string = ROUTE_TRANSLATIONS[to]?.[locale] ?? to;
      if (DEFAULT_LOCALE !== locale && !UNPREFIXED_ROUTES.includes(slug)) {
        return `/${locale}${slug}`;
      }
      return slug;
    },
    [localeDefault]
  );
}
