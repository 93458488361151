import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  root: {
    marginTop: 100,
    marginBottom: 100,
    [theme.fn.smallerThan('md')]: {
      marginTop: 60,
      marginBottom: 60,
    },
    [theme.fn.smallerThan('xs')]: {
      marginTop: 40,
      marginBottom: 40,
    },
  },
}));
