import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Burger, Button, Group } from '@aviationexam/core';
import { useScrollLock } from '@aviationexam/hooks';
import { APP_PAGE, HOME_PAGE, REGISTER_PAGE } from 'config/routes';
import { LocalizedLink, usePublicTranslation } from 'features/localization';
import { Logo } from '../../Logo/Logo';
import { DropdownNavigation, DrawerNavigation } from './Navigation';
import { LanguageSwitcher } from './LanguageSwitcher/LanguageSwitcher';
import useStyles from './Header.styles';

export function Header() {
  const { t } = usePublicTranslation();
  const location = useLocation();
  const [mobileNavOpened, setMobileNavOpened] = useState(false);
  const { classes, cx } = useStyles({ hasRadius: !mobileNavOpened });

  useScrollLock(mobileNavOpened);

  useEffect(() => {
    setMobileNavOpened(false);
  }, [location.pathname]);

  return (
    <div className={classes.root}>
      <LocalizedLink to={HOME_PAGE} title="Aviationexam">
        <Logo variant="large" className={cx(classes.logo, classes.logoLarge)} />
        <Logo variant="small" className={cx(classes.logo, classes.logoSmall)} />
      </LocalizedLink>
      <div className={classes.dropdownNav}>
        <nav className={classes.nav}>
          <DropdownNavigation offsetTop={65} />
        </nav>
        <div>
          <Group>
            <a href={APP_PAGE} target="_blank" rel="noreferrer" className={classes.navLink}>
              {t('navigation.login')}
            </a>
            <Button<'a'>
              size="xs"
              uppercase
              component="a"
              className={classes.signUpLink}
              href={REGISTER_PAGE(location.href)}
            >
              {t('navigation.signUp')}
            </Button>
            <LanguageSwitcher />
          </Group>
        </div>
      </div>
      <div className={classes.mobileNav}>
        <div style={{ flexGrow: 1 }} />
        <Burger
          size="md"
          className={classes.burger}
          opened={mobileNavOpened}
          onClick={() => setMobileNavOpened(o => !o)}
        />
      </div>
      <DrawerNavigation opened={mobileNavOpened} />
    </div>
  );
}
