import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 2c-1.06 0-2.11.087-3.135.227a1 1 0 1 0 .27 1.982A21.27 21.27 0 0 1 25 4c7.576 0 14.148 4.015 17.848 10H36a1 1 0 1 0 0 2h9a1 1 0 0 0 1-1V6a1.001 1.001 0 1 0-2 0v6.107C39.865 6.026 32.923 2 25 2ZM6 4a1 1 0 1 0 0 2h6.023A23.011 23.011 0 0 0 2 25c0 1.083.063 2.11.19 3.123a1 1 0 1 0 1.984-.246A23.066 23.066 0 0 1 4 25c0-7.532 3.98-14.213 10-17.908V14a1 1 0 1 0 2 0V5a1 1 0 0 0-1-1H6Zm40.748 16.986a1 1 0 0 0-.945 1.143c.123.947.197 1.914.197 2.871 0 7.99-4.467 14.859-11 18.424V36a1 1 0 1 0-2 0v9a1 1 0 0 0 1 1h9a1 1 0 1 0 0-2h-5.107C43.974 39.865 48 32.923 48 25c0-1.059-.08-2.11-.213-3.129a1 1 0 0 0-1.039-.885ZM5 34a1 1 0 0 0-1 1v9a1 1 0 1 0 2 0v-6.08C10.15 44.006 17.135 48 25 48c.713 0 1.404-.027 2.082-.082a1.002 1.002 0 0 0 .224-1.95 1 1 0 0 0-.388-.044A23.49 23.49 0 0 1 25 46c-7.527 0-14.16-3.999-17.865-10H14a1 1 0 1 0 0-2H5ZM20.664 25.133c0-4.469 1.82-6.703 5.461-6.703.573 0 1.057.044 1.453.133v1.53a4.96 4.96 0 0 0-1.375-.171c-1.224 0-2.143.328-2.758.984-.614.657-.948 1.709-1 3.157h.094a2.488 2.488 0 0 1 1.031-.977c.443-.234.964-.352 1.563-.352 1.036 0 1.844.318 2.422.953.578.636.867 1.498.867 2.586 0 1.198-.336 2.146-1.008 2.844-.667.693-1.578 1.04-2.734 1.04-.818 0-1.529-.196-2.133-.587-.604-.396-1.07-.968-1.399-1.718-.322-.756-.484-1.662-.484-2.72Zm3.984 3.508c.63 0 1.115-.203 1.454-.61.343-.406.515-.987.515-1.742 0-.656-.161-1.172-.484-1.547-.318-.375-.797-.562-1.438-.562-.396 0-.76.086-1.093.258a1.969 1.969 0 0 0-.79.695c-.192.291-.289.591-.289.898 0 .735.198 1.354.594 1.86.401.5.912.75 1.531.75Z"
        fill="#CB1368"
      />
    </svg>
  );
}

export default SvgDefault;
