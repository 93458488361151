import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#default_svg__a)" fill="#32D74B">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.466 2.712C14.247 1.017 19.393 0 25 0c5.632 0 10.778 1.017 14.553 2.713C43.237 4.367 46 6.863 46 10v17.74l-2 1V24.3C40.68 27.7 33.52 30 25 30S9.32 27.7 6 24.3V30c0 3.86 7.63 8 18.99 8.02 1.11 0 2.2-.05 3.2-.12-.123.66-.187 1.329-.19 2-1 .1-2 .1-3 .1-8.52 0-15.68-2.3-19-5.7V39c0 3.86 7.63 8 19 8 1.688 0 3.374-.1 5.05-.3a12.51 12.51 0 0 0 1.5 1.82A43.94 43.94 0 0 1 25 49C13.22 49 4 44.61 4 39V10c0-3.14 2.779-5.635 6.466-7.288Zm28.268 1.825C42.288 6.133 44 8.137 44 10c0 1.86-1.721 3.865-5.284 5.463C35.247 17.017 30.393 18 25 18c-5.393 0-10.247-.983-13.716-2.537C7.721 13.864 6 11.86 6 10c0-1.86 1.721-3.865 5.284-5.462C14.753 2.983 19.607 2 25 2c5.418 0 10.272.983 13.734 2.537Zm-28.268 12.75C8.7 16.498 7.144 15.513 6 14.358V20c0 3.86 7.63 8 19 8 11.37 0 19-4.14 19-8v-5.644c-1.144 1.156-2.7 2.14-4.466 2.932C35.753 18.983 30.607 20 25 20c-5.607 0-10.753-1.017-14.534-2.712Z"
        />
        <path d="M48.293 39.293A1 1 0 0 1 49 39a.941.941 0 0 1 1 1 10 10 0 1 1-10-10 9.44 9.44 0 0 1 7 3.2V30a1 1 0 0 1 2 0v7h-7a1 1 0 0 1 0-2h3.9a7.51 7.51 0 0 0-5.9-3 8 8 0 1 0 8 8 1 1 0 0 1 .293-.707Z" />
      </g>
      <defs>
        <clipPath id="default_svg__a">
          <path fill="#fff" d="M0 0h50v50H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgDefault;
