import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.648 36.732C44.686 33.312 48 27.538 48 21c0-10.482-8.518-19-19-19-6.538 0-12.312 3.314-15.732 8.352l1.436 1.436A16.975 16.975 0 0 1 29 4c9.4 0 17 7.6 17 17 0 6.003-3.1 11.272-7.788 14.296l1.436 1.436Zm-5.258.398 1.556 1.557A18.916 18.916 0 0 1 29 40c-10.482 0-19-8.518-19-19 0-2.45.466-4.794 1.313-6.946l1.556 1.556A17.02 17.02 0 0 0 12 21c0 9.4 7.6 17 17 17a17.02 17.02 0 0 0 5.39-.87Zm-3.685-9.34C33.74 27.023 36 24.265 36 21c0-3.854-3.146-7-7-7-3.266 0-6.024 2.26-6.79 5.295l1.79 1.79V21c0-2.773 2.227-5 5-5s5 2.227 5 5a4.985 4.985 0 0 1-5.085 5l1.79 1.79ZM6 25c0-3.679 1.069-7.105 2.887-10.018a20.98 20.98 0 0 0-.78 8.153A16.894 16.894 0 0 0 8 25c0 9.374 7.626 17 17 17 .631 0 1.252-.04 1.865-.107a20.98 20.98 0 0 0 8.152-.78A18.856 18.856 0 0 1 25 44C14.523 44 6 35.477 6 25Zm-4 4c0-3.679 1.069-7.105 2.887-10.018a20.98 20.98 0 0 0-.78 8.153A16.894 16.894 0 0 0 4 29c0 9.374 7.626 17 17 17 .631 0 1.252-.04 1.865-.107a20.98 20.98 0 0 0 8.152-.78A18.856 18.856 0 0 1 21 48C10.523 48 2 39.477 2 29Zm25.586-9.414a2 2 0 1 1 2.828 2.828 2 2 0 0 1-2.828-2.828Z"
        fill="#06C"
      />
      <path fill="#06C" d="M49.352 47.938 2.415 1.002 1 2.415l46.938 46.938z" />
    </svg>
  );
}

export default SvgDefault;
