import React from 'react';
import useStyles from './LinkContent.styles';

export interface LinkContentProps {
  icon?: React.ReactNode;
  title: string;
  description?: string;
}
export function LinkContent({ icon, title, description }: LinkContentProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <div className={classes.captions}>
        <span className={classes.title}>{title}</span>
        {description && <span className={classes.description}>{description}</span>}
      </div>
    </div>
  );
}
