import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 93"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={46} cy={53} r={40} fill="#06C" fillOpacity={0.15} />
      <path
        d="M37 .2C16.695.2.2 16.695.2 37 .2 57.305 16.695 73.8 37 73.8c20.305 0 36.8-16.495 36.8-36.8C73.8 16.695 57.305.2 37 .2Zm0 3.2c18.576 0 33.6 15.024 33.6 33.6S55.576 70.6 37 70.6 3.4 55.576 3.4 37 18.424 3.4 37 3.4Zm.028 16a1.6 1.6 0 0 0-.678.138l-28.8 12.8a1.6 1.6 0 0 0-.281 2.765l4.131 2.95v8.994A3.192 3.192 0 0 0 9.8 49.8c0 1.76 3.2 6.4 3.2 6.4s3.2-4.64 3.2-6.4a3.192 3.192 0 0 0-1.6-2.753V40.34l3.2 2.284V45c0 .815.457 1.453.894 1.84.436.388.914.642 1.456.876 1.085.466 2.456.82 4.119 1.121 3.326.605 7.792.963 12.731.963s9.405-.358 12.731-.962c1.663-.303 3.034-.656 4.119-1.122.542-.233 1.02-.488 1.456-.875.437-.388.894-1.026.894-1.841v-2.375l10.531-7.522a1.6 1.6 0 0 0-.281-2.766l-28.8-12.8a1.601 1.601 0 0 0-.622-.137ZM37 22.75l25.59 11.375-6.39 4.566V37c0-.698-.339-1.325-.725-1.737-.387-.413-.83-.691-1.325-.938-.99-.494-2.232-.85-3.81-1.156-3.155-.613-7.626-.969-13.34-.969-5.715 0-10.186.356-13.34.969-1.578.306-2.82.663-3.81 1.156-.495.247-.939.525-1.325.938-.387.412-.725 1.04-.725 1.737v1.69l-6.39-4.565L37 22.75Zm0 12.65c5.578 0 9.91.362 12.731.91 1.411.273 2.449.61 2.994.88.181.091.213.125.275.172v4.635a22.166 22.166 0 0 0-3.269-.834C46.405 40.558 41.94 40.2 37 40.2s-9.405.358-12.731.962c-1.28.233-2.333.514-3.269.835v-4.635c.062-.047.094-.081.275-.171.545-.272 1.583-.608 2.994-.882C27.09 35.762 31.422 35.4 37 35.4Zm0 8c4.781 0 9.115.36 12.16.913 1.146.208 1.875.452 2.58.687-.705.235-1.434.479-2.58.688-3.045.553-7.379.912-12.16.912-4.782 0-9.115-.359-12.16-.913-1.146-.208-1.875-.452-2.58-.687.705-.235 1.433-.479 2.58-.688 3.045-.553 7.378-.912 12.16-.912Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
