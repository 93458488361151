import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.982 2a1 1 0 0 0-.447.113l-21 11A1 1 0 0 0 3 14v31a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V14a1 1 0 0 0-.535-.887l-21-11A1 1 0 0 0 24.982 2ZM25 4.129l20 10.476V44H30v-9a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v9H5V14.605L25 4.13ZM8 16a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H8Zm13 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-8Zm14 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-7ZM9 18h5v3H9v-3Zm13 0h6v3h-6v-3Zm14 0h5v3h-5v-3ZM8 25a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H8Zm13 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-8Zm14 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-7ZM9 27h5v3H9v-3Zm13 0h6v3h-6v-3Zm14 0h5v3h-5v-3ZM8 34a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H8Zm27 0a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-7ZM9 36h5v3H9v-3Zm13 0h6v8h-6v-8Zm14 0h5v3h-5v-3Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
