import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 38"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      color="#fff"
      className="default_svg__sc-hBUSln default_svg__jLdqKu"
      {...props}
    >
      <path d="M20 .6c-9.002 0-16.362 7.096-16.774 16H2.4c-.875 0-1.6.725-1.6 1.6V23c0 .875.725 1.6 1.6 1.6h.8v7.6l.325.25S10.307 37.4 20 37.4c9.694 0 16.475-4.95 16.475-4.95l.325-.25v-7.6h.8c.875 0 1.6-.725 1.6-1.6v-4.8c0-.875-.725-1.6-1.6-1.6h-.825C36.363 7.696 29.004.6 20 .6Zm0 1.6c8.404 0 15.2 6.796 15.2 15.2v13.95c-.827.572-6.7 4.45-15.2 4.45S5.63 31.922 4.8 31.35V17.4C4.8 8.996 11.599 2.2 20 2.2Zm0 5.6c-4.9 0-8.312 1.228-10.5 3.075-2.187 1.847-3.1 4.293-3.1 6.525v4.95c0 2.606 1.413 4.859 3.8 6.35 2.388 1.49 5.732 2.3 9.8 2.3 4.07 0 7.413-.81 9.8-2.3 2.104-1.316 3.435-3.232 3.726-5.45.037-.297.075-.591.075-.9V17.4c0-2.203-.916-4.647-3.1-6.5-2.185-1.854-5.597-3.1-10.5-3.1Zm0 1.6c4.632 0 7.62 1.171 9.45 2.725C31.283 13.678 32 15.634 32 17.4v4.95c0 .162-.012.318-.024.475-.031.09-.156.415-.55.825-.453.472-1.178.95-2.5.95h-3.55A1.586 1.586 0 0 0 24 23.8a1.599 1.599 0 1 0 0 3.2c.59 0 1.097-.322 1.375-.8h3.55c.644 0 1.2-.113 1.7-.275-.453.515-.994 1-1.675 1.425-2.032 1.268-5.094 2.05-8.95 2.05-3.857 0-6.919-.782-8.95-2.05C9.02 26.08 8 24.396 8 22.35V17.4c0-1.8.722-3.757 2.55-5.3 1.828-1.544 4.815-2.7 9.45-2.7ZM2.4 18.2h.8V23h-.8v-4.8Zm34.4 0h.8V23h-.8v-4.8Z" />
    </svg>
  );
}

export default SvgDefault;
