import { AviNumberSize, createStyles } from '@aviationexam/core';

const sizes = {
  xs: 20,
  sm: 30,
  md: 40,
  lg: 50,
  xl: 60,
};

interface IconCardStyles {
  size: AviNumberSize;
}

export default createStyles((theme, { size }: IconCardStyles) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    backgroundColor: theme.colors.blue[0],
    marginLeft: (theme.fn.size({ size, sizes }) / 3) * 2,
    padding: theme.fn.size({ size, sizes }) / 3,
  },
  icon: {
    width: theme.fn.size({ size, sizes }),
    height: theme.fn.size({ size, sizes }),
    marginBottom: -theme.fn.size({ size, sizes }) / 3,
    boxShadow: theme.shadows.lg,
    zIndex: 1,
  },
  iconInfo: {
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), ${theme.colors.blue[7]}`,
    boxShadow: '0 10px 15px -3px rgba(0, 102, 204, 0.3), 0 4px 6px -2px rgba(0, 102, 204, 0.5)',
  },
  iconDanger: {
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), ${theme.colors.red[7]}`,
    boxShadow: '0 10px 15px -3px rgba(255, 82, 54, 0.3), 0 4px 6px -2px rgba(255, 82, 54, 0.5)',
  },
}));
