import { SimpleGrid } from '@aviationexam/core';
import React from 'react';
import useStyles, { IMAGE_WRAP_BREAKPOINT } from './PageHeader.styles';

interface PageHeaderProps extends React.ComponentPropsWithoutRef<'div'> {
  offsetHeader?: boolean;
  backdrop?: string;
  color?: string;
  shadow?: string;
  image: React.ReactNode;
}

export function PageHeader({
  offsetHeader = false,
  image,
  backdrop,
  color,
  shadow,
  children,
  className,
  ...others
}: PageHeaderProps) {
  const { cx, classes } = useStyles({ offsetHeader, color, shadow, backdrop });

  return (
    <div className={cx(classes.root, className)} {...others}>
      <div className={classes.headerContainer}>
        <SimpleGrid
          cols={2}
          breakpoints={[{ maxWidth: IMAGE_WRAP_BREAKPOINT, cols: 1 }]}
          className={classes.header}
        >
          <div className={classes.titleWrapper}>{children}</div>
          <div className={classes.imageWrapper}>{image}</div>
        </SimpleGrid>
      </div>
    </div>
  );
}
