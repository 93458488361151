import { createStyles, AviTheme } from '@aviationexam/core';

interface FullWidthStyles {
  backgroundColor: string | ((theme: AviTheme) => string);
}

export const useFullWidthStyles = createStyles((theme, { backgroundColor }: FullWidthStyles) => ({
  background: {
    position: 'relative',
    zIndex: 1,
    '& > div': {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '100%',
      '&:before': {
        content: '" "',
        zIndex: -1,
        position: 'absolute',
        top: 0,
        left: '50%',
        height: '100%',
        transform: 'translateX(-50%)',
        width: '100vw',
        backgroundColor:
          typeof backgroundColor === 'string' ? backgroundColor : backgroundColor(theme),
      },
    },
  },
}));
