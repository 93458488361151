import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 84 91"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={44} cy={51} r={40} fill="#32D74B" fillOpacity={0.15} />
      <path
        d="M34.005.2c-8.818 0-16 7.18-16 16 0 5.243 2.232 8.862 4.25 11.9 2.02 3.037 3.75 5.48 3.75 9v9.8l-24.3 4.55A1.604 1.604 0 0 0 .406 53v12.8c0 .88.72 1.6 1.6 1.6V69c0 2.63 2.17 4.8 4.8 4.8h54.4c2.632 0 4.8-2.17 4.8-4.8v-1.6c.882 0 1.6-.72 1.6-1.6V53a1.604 1.604 0 0 0-1.3-1.55l-24.3-4.55v-9.8c0-3.52 1.732-5.963 3.75-9 2.02-3.038 4.25-6.657 4.25-11.9 0-8.82-7.18-16-16-16Zm0 3.2c7.088 0 12.8 5.712 12.8 12.8 0 4.35-1.768 7.112-3.75 10.1-1.98 2.987-4.25 6.224-4.25 10.8v9.5h-9.6v-9.5c0-4.576-2.268-7.813-4.25-10.8-1.98-2.988-3.75-5.75-3.75-10.1 0-7.088 5.713-12.8 12.8-12.8Zm-6.5 46.4H40.556l23.85 4.5v9.9h-60.8v-9.9l23.9-4.5Zm-22.3 17.6h57.6V69c0 .906-.693 1.6-1.6 1.6h-54.4c-.906 0-1.6-.694-1.6-1.6v-1.6Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
