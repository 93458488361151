import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0c-1.91 0-3.758.32-5.5 1.086a.99.99 0 0 0-.617.805.983.983 0 0 0 .406.93.993.993 0 0 0 1.012.093C10.758 2.277 12.312 2 14 2c6.664 0 12 5.336 12 12s-5.336 12-12 12S2 20.664 2 14c0-3.04 1.164-5.758 3-7.914v2.613c-.004.36.184.696.496.88.313.179.695.179 1.008 0 .312-.184.5-.52.496-.88V3H1.3a1.006 1.006 0 0 0-.878.496 1.01 1.01 0 0 0 0 1.008c.183.312.52.5.879.496h1.992C1.285 7.45 0 10.555 0 14c0 7.738 6.262 14 14 14s14-6.262 14-14S21.738 0 14 0Zm24 0c-1.094 0-2 .906-2 2v2h-9.531c.508.629.96 1.3 1.367 2H36v2c0 1.094.906 2 2 2h2c1.094 0 2-.906 2-2V6h5v7H29.95c.019.332.05.66.05 1 0 .34-.031.668-.05 1H47v31H5V27.219a16.392 16.392 0 0 1-2-1.63V46c0 1.16.84 2 2 2h42c1.16 0 2-.84 2-2V6c0-1.16-.84-2-2-2h-5V2c0-1.094-.906-2-2-2h-2Zm0 2h2v6h-2V2ZM13.984 4.984c-.55.012-.992.465-.984 1.016v7.84a.994.994 0 0 0 .11.625c.09.18.234.328.41.426l3.882 2.91a.995.995 0 0 0 1.399-.203.995.995 0 0 0-.203-1.399L15 13.5V6a1.003 1.003 0 0 0-1.016-1.016ZM29.188 19a15.478 15.478 0 0 1-.825 2H32v5h-7v7h-5v-4.176c-.645.262-1.316.473-2 .649V33h-5v-3.05a15.988 15.988 0 0 1-2-.243V42h30V19H29.187ZM34 21h5v5h-5v-5Zm-7 7h5v5h-5v-5Zm7 0h5v5h-5v-5Zm-21 7h5v5h-5v-5Zm7 0h5v5h-5v-5Zm7 0h5v5h-5v-5Zm7 0h5v5h-5v-5Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgDefault;
