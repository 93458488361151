export const HOME_PAGE = '/';
export const NOT_FOUND_PAGE = '/404';
export const ABOUT_PAGE = '/about';
export const CONTACT_PAGE = '/contact';
export const REVIEWS_PAGE = '/reviews';
export const CUSTOMER_REVIEWS_PAGE = '/customer-reviews';
export const QUESTION_BANKS_EASA_PAGE = '/question-banks/easa';
export const QUESTION_BANKS_EASA_GERMAN_PAGE = '/question-banks/easa-german';
export const QUESTION_BANKS_PPL_PAGE = '/question-banks/ppl';
export const LMS_PAGE = '/solutions/lms';
export const ES_PAGE = '/solutions/caa';
export const FEATURES_PAGE = '/solutions/app';
export const TEXTBOOKS_PAGE = '/products/textbooks';
export const BOOKS_PAGE = '/products/books';
export const SUBSCRIPTION_PAGE = '/products/subscription';
export const DOWNLOADS_PAGE = '/downloads';
export const BLOG_PAGE = '/blog';
export const API_BLOG_PAGE = '/api-blog';
export const GDPR_PAGE = '/gdpr';
export const TAC_PAGE = '/terms-and-conditions';
export const NEWSLETTER_SUBSCRIPTION_PAGE = '/newsletter-subscriptions';

export const APP_PAGE = '/app';
export const REGISTER_PAGE = (currentPath: string): string =>
  `/auth/Account/register?returnUrl=${currentPath}`;

export const ROUTE_TRANSLATIONS: Record<string, Record<'en' | 'de', string>> = {
  [NOT_FOUND_PAGE]: {
    en: '/404',
    de: '/404',
  },
  [HOME_PAGE]: {
    en: '/',
    de: '/',
  },
  [ABOUT_PAGE]: {
    en: '/about',
    de: '/uber-uns',
  },
  [CONTACT_PAGE]: {
    en: '/contact',
    de: '/kontakt',
  },
  [REVIEWS_PAGE]: {
    en: '/reviews',
    de: '/rezensieren',
  },
  [CUSTOMER_REVIEWS_PAGE]: {
    en: '/ato-reviews',
    de: '/kundenrezensionen',
  },
  [QUESTION_BANKS_EASA_PAGE]: {
    en: '/question-banks/easa',
    de: '/fragenbanken/easa',
  },
  [QUESTION_BANKS_EASA_GERMAN_PAGE]: {
    en: '/question-banks/easa-german',
    de: '/fragenbanken/easa-deutsch',
  },
  [QUESTION_BANKS_PPL_PAGE]: {
    en: '/question-banks/ppl-german',
    de: '/fragenbanken/ppl-deutsch',
  },
  [LMS_PAGE]: {
    en: '/solutions/learning-management-system',
    de: '/losungen/lernmanagementsystem',
  },
  [ES_PAGE]: {
    en: '/solutions/examination-system',
    de: '/losungen/prufungssystem',
  },
  [FEATURES_PAGE]: {
    en: '/solutions/features',
    de: '/losungen/eigenschaften',
  },
  [TEXTBOOKS_PAGE]: {
    en: '/products/e-textbooks',
    de: '/produkte/e-lehrbucher',
  },
  [BOOKS_PAGE]: {
    en: '/products/e-books',
    de: '/produkte/e-bucher',
  },
  [SUBSCRIPTION_PAGE]: {
    en: '/products/subscription',
    de: '/produkte/abonnement',
  },
  [DOWNLOADS_PAGE]: {
    en: '/downloads',
    de: '/herunterladen',
  },
  [BLOG_PAGE]: {
    en: '/news',
    de: '/neuigkeiten',
  },
  [API_BLOG_PAGE]: {
    en: '/api-news',
    de: '/api-neuigkeiten',
  },
  [NEWSLETTER_SUBSCRIPTION_PAGE]: {
    en: '/newsletter-subscriptions',
    de: '/newsletter-abonnements',
  },
};
