import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 45 42"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.72.72A2.884 2.884 0 0 0 .84 3.6v28.8a2.883 2.883 0 0 0 2.88 2.88h22.582c-.306-.601-.588-1.245-.85-1.92H3.72a.96.96 0 0 1-.96-.96v-24h36.48V19.84c.192.093.384.187.572.281.41.206.844.413 1.348.62V3.6A2.883 2.883 0 0 0 38.28.72H3.72Zm0 1.92h34.56c.53 0 .96.43.96.96v2.88H2.76V3.6c0-.53.43-.96.96-.96Zm.96.96a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92Zm2.88 0a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92Zm2.88 0a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92ZM35.4 20.784c-1.44 0-2.4.48-3.551 1.056-1.152.576-2.592 1.248-5.28 1.824l-.77.191v.77c0 12.48 8.833 16.992 9.12 17.184l.48.19.48-.19c.385-.192 9.12-5.089 9.12-17.185v-.769l-.768-.19c-2.688-.577-4.128-1.25-5.28-1.825-1.152-.576-2.111-1.056-3.551-1.056Zm0 1.92c1.056 0 1.633.288 2.689.864 1.152.48 2.59 1.247 4.99 1.823-.287 8.928-6.047 13.344-7.68 14.4-1.727-.96-7.391-5.088-7.68-14.4 2.497-.576 3.936-1.342 4.992-1.822 1.056-.576 1.633-.865 2.689-.865Z"
        fill="#CB1368"
      />
    </svg>
  );
}

export default SvgDefault;
