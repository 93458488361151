import React from 'react';
import { ActionIcon, Group, Text } from '@aviationexam/core';
import { ArrowDownIcon } from '@aviationexam/icons';
import useStyles, { HoverCardStyles } from './HoverCard.styles';

export interface HoverCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  /** Used on hover */
  backgroundColor?: HoverCardStyles['backgroundColor'];
  /** Used on hover */
  color?: HoverCardStyles['color'];
  className?: string;
  style?: React.CSSProperties;
}

export function HoverCard({
  icon,
  title,
  description,
  className,
  backgroundColor = '#DAE8F8',
  color = '#343a40',
  ...others
}: HoverCardProps) {
  const { classes, cx } = useStyles({ backgroundColor, color });
  return (
    <div className={cx(classes.card, className)} {...others}>
      <Group direction="column" grow>
        <div>{icon}</div>
        <Text className={classes.title}>{title}</Text>
      </Group>
      {description && (
        <div className={classes.hoverCard}>
          <Group direction="column" spacing="xs">
            <Text className={classes.title}>{title}</Text>
            <Text className={classes.description} style={{ flexGrow: 1 }}>
              {description}
            </Text>
          </Group>
        </div>
      )}
      <ActionIcon
        variant="filled"
        color="blue"
        radius="xl"
        size="lg"
        className={classes.actionIcon}
      >
        <ArrowDownIcon variant="solid" />
      </ActionIcon>
    </div>
  );
}
