import * as React from 'react';
import { SVGProps } from 'react';

function SvgJet(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.812 0a.984.984 0 0 0-.636.43.998.998 0 0 0-.145.757L12.656 19h-.25l-7-6.719a1.004 1.004 0 0 0-1.156-.187L.562 13.906a.997.997 0 0 0-.468 1.344l3.593 7.063v3.28L.094 32.75a.997.997 0 0 0 .469 1.344l3.687 1.812c.387.192.851.117 1.156-.187l7-6.719h.375l-3.75 17.781a.995.995 0 0 0 .192.84c.19.238.476.375.777.379h3.906c.29 0 .563-.125.75-.344L20.5 40.97h3.594a.971.971 0 0 0 .925-.453.98.98 0 0 0 0-1.032.971.971 0 0 0-.925-.453h-1.907L30.937 29h6.97c5.984 0 11.687-4.188 11.687-4.188.261-.187.418-.492.418-.812 0-.32-.157-.625-.418-.813 0 0-5.61-4.187-11.688-4.187h-6.969l-8.75-10.031h1.907a.97.97 0 0 0 .925-.453.98.98 0 0 0 0-1.032.97.97 0 0 0-.925-.453H20.5L14.656.344a.986.986 0 0 0-.75-.344H9.812Zm1.407 2h2.25l16.28 18.656a.986.986 0 0 0 .75.344h7.407c4.023 0 7.45 1.992 9.063 3-1.633 1.008-5.118 3-9.063 3H30.5a.986.986 0 0 0-.75.344L13.469 46h-2.25l3.75-17.781a.995.995 0 0 0-.192-.84A1.012 1.012 0 0 0 14 27h-2a1 1 0 0 0-.688.281l-6.812 6.5-2.156-1.031 3.25-6.5a.991.991 0 0 0 .093-.438v-3.718a.992.992 0 0 0-.093-.438l-3.25-6.406L4.5 14.219l6.812 6.5A1 1 0 0 0 12 21h1.906c.297-.004.574-.14.762-.367.187-.23.262-.531.207-.82L11.219 2Z"
        fill="#32D74B"
      />
    </svg>
  );
}

export default SvgJet;
