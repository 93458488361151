import { createStyles } from '@aviationexam/core';

export default createStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  newsletter: {
    backgroundColor: '#0B2E73',
    position: 'relative',
    padding: [50, 0].map(p => `${p}px`).join(' '),
    color: theme.white,
  },
  newsletterTitle: {
    color: theme.white,
    textAlign: 'center',
  },
  newsletterSubtitle: {
    textTransform: 'uppercase',
    color: theme.white,
    marginBottom: theme.spacing.lg,
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
  },
  newsletterFormButton: {
    backgroundColor: '#0B2E73',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: `1px solid ${theme.white}`,
    borderLeft: 'none',
    height: '100%',
  },
  blendLeft: {
    position: 'absolute',
    left: -249,
    top: -289,
    width: 497,
    height: 497,
    borderRadius: '100%',
    background: 'linear-gradient(112.68deg, #FFFFFF 50.75%, rgba(255, 255, 255, 0) 90.84%)',
    mixBlendMode: 'soft-light',
  },
  blendRight: {
    position: 'absolute',
    right: -100,
    top: -306,
    width: 375,
    height: 497,
    borderRadius: '90%',
    background: 'linear-gradient(16.13deg, #FFFFFF 10.92%, rgba(255, 255, 255, 0) 88.81%)',
    mixBlendMode: 'soft-light',
    transform: 'rotate(41.67deg)',
    opacity: 0.3,
  },
  links: {
    backgroundColor: theme.colors.gray[8],
    position: 'relative',
    padding: [50, 0].map(p => `${p}px`).join(' '),
    color: theme.white,
  },
  linkTitle: {
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    letterSpacing: 2,
    fontWeight: 700,
    textTransform: 'uppercase',
    zIndex: 1,
  },
  link: {
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    opacity: 0.8,
    textDecoration: 'none',
    zIndex: 1,
  },
  imageWrapper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 0,
    maxWidth: '100%',
    opacity: 0.3,
  },
}));
