import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 51 44"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.984 0a.976.976 0 0 0-.691.297L11.683 13H2.103c-.524 0-1.055.191-1.434.566-.379.38-.566.91-.566 1.434v4c0 .523.187 1.055.566 1.434.379.375.91.566 1.434.566h.156L7.64 38.89l.023.063C8.086 40.074 9.156 41 10.5 41h19.379l-.899-2H10.5c-.457 0-.785-.277-.96-.75L3.956 19.711a1 1 0 0 0-.957-.71h-.898v-4H37.89c.156.038.32.038.476 0h9.73v4H47.2c-.035 0-.066 0-.101.003a.997.997 0 0 0-.903.86l-2.066 6.624 1.887.665L47.938 21h.16c.523 0 1.054-.191 1.433-.566.38-.38.567-.91.567-1.434v-4c0-.523-.188-1.055-.567-1.434-.379-.375-.91-.566-1.433-.566h-9.586L25.703.29a1.006 1.006 0 0 0-.719-.29Zm.02 2.414L35.672 13H14.5L25.004 2.414ZM25.105 22a1 1 0 0 0-.949 1.41l9 20a1.001 1.001 0 0 0 1.809.04l2.422-4.852 5.96 5.156c.204.176.47.262.735.242a.993.993 0 0 0 .684-.351l3.097-3.668a.988.988 0 0 0 .235-.735.987.987 0 0 0-.36-.68l-6.02-4.995 3.923-2.75a.995.995 0 0 0-.243-1.758l-20-7a.929.929 0 0 0-.293-.059Zm1.832 2.715 15.961 5.582-3.402 2.387a.994.994 0 0 0-.426.777c-.011.309.121.61.36.809l6.258 5.187-1.797 2.125-6.168-5.336a1.006 1.006 0 0 0-.852-.226 1.01 1.01 0 0 0-.7.53l-2.058 4.118-7.175-15.953Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
