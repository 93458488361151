import React from 'react';
import { usePublicTranslation } from 'features/localization';
import Animated from '../../images/memojis/ondra_animated.gif';
import Static from '../../images/memojis/ondra_static.gif';
import { Memoji } from './Memoji';

export function OndraMemoji() {
  const { t } = usePublicTranslation();
  return (
    <Memoji
      src={Static}
      animatedSrc={Animated}
      name={t('team.ondra.name')}
      title={t('team.ondra.title')}
      color="rgba(255, 82, 54, 0.15)"
      backdrop="center"
    />
  );
}
