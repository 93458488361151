import React from 'react';
import {
  SitemapIcon,
  StarIcon,
  MailIcon,
  ShieldCheckIcon,
  DownloadIcon,
  ProjectIcon,
  ListIcon,
} from '@aviationexam/icons';
import { createStyles, useAviTheme } from '@aviationexam/core';
import { LocalizedLink, usePublicTranslation, useLocale } from 'features/localization';
import {
  CountryFlagIcon,
  EducationIcon,
  ClassroomIcon,
  StampIcon,
  DaecIcon,
  CloudIcon,
  BookIcon,
  TextbookIcon,
} from 'assets/icons';
import {
  LMS_PAGE,
  ES_PAGE,
  CONTACT_PAGE,
  REVIEWS_PAGE,
  ABOUT_PAGE,
  QUESTION_BANKS_EASA_PAGE,
  QUESTION_BANKS_EASA_GERMAN_PAGE,
  QUESTION_BANKS_PPL_PAGE,
  BLOG_PAGE,
  GDPR_PAGE,
  DOWNLOADS_PAGE,
  CUSTOMER_REVIEWS_PAGE,
  SUBSCRIPTION_PAGE,
  BOOKS_PAGE,
  TEXTBOOKS_PAGE,
  FEATURES_PAGE,
  TAC_PAGE,
} from 'config/routes';
import { LinkContent } from '../LinkContent/LinkContent';
import { DropdownProvider } from './DropdownProvider';
import { DropdownOption, useStyles as useDropdownOptionStyles } from './DropdownOption';
import { DropdownRoot } from './DropdownRoot';

const useStyles = createStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    padding: theme.spacing.xl,
  },
  list: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    margin: '0',
    padding: '0',
    height: '100%',
    listStyleType: 'none',
  },
  icon: {
    width: 30,
    height: 30,
    borderRadius: 9999,
    overflow: 'hidden',
  },
}));

function Solutions() {
  const { t } = usePublicTranslation();
  const { classes } = useStyles();
  return (
    <div className={classes.content}>
      <LocalizedLink to={FEATURES_PAGE}>
        <LinkContent
          icon={<EducationIcon size={30} />}
          title={t('navigation.solutions.students.title')}
          description={t('navigation.solutions.students.description')}
        />
      </LocalizedLink>
      <LocalizedLink to={LMS_PAGE}>
        <LinkContent
          icon={<ClassroomIcon size={30} />}
          title={t('navigation.solutions.schools.title')}
          description={t('navigation.solutions.schools.description')}
        />
      </LocalizedLink>
      <LocalizedLink to={ES_PAGE}>
        <LinkContent
          icon={<StampIcon size={30} />}
          title={t('navigation.solutions.authorities.title')}
          description={t('navigation.solutions.authorities.description')}
        />
      </LocalizedLink>
    </div>
  );
}

function Products() {
  const { t } = usePublicTranslation();
  const theme = useAviTheme();
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.content}>
        <LocalizedLink to={SUBSCRIPTION_PAGE}>
          <LinkContent
            icon={<CloudIcon size={30} />}
            title={t('navigation.products.subscription.title')}
            description={t('navigation.products.subscription.description')}
          />
        </LocalizedLink>
        <LocalizedLink to={BOOKS_PAGE}>
          <LinkContent
            icon={<BookIcon size={30} />}
            title={t('navigation.products.books.title')}
            description={t('navigation.products.books.description')}
          />
        </LocalizedLink>
        <LocalizedLink to={TEXTBOOKS_PAGE}>
          <LinkContent
            icon={<TextbookIcon size={30} />}
            title={t('navigation.products.textbooks.title')}
            description={t('navigation.products.textbooks.description')}
          />
        </LocalizedLink>
      </div>
      <div className={classes.content} style={{ backgroundColor: theme.colors.gray[2] }}>
        <LocalizedLink to={DOWNLOADS_PAGE}>
          <LinkContent
            icon={<DownloadIcon size={30} />}
            title={t('navigation.products.downloads.title')}
            description={t('navigation.products.downloads.description')}
          />
        </LocalizedLink>
      </div>
    </>
  );
}

function Company() {
  const { t } = usePublicTranslation();
  const theme = useAviTheme();
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.content}>
        <LocalizedLink to={ABOUT_PAGE}>
          <LinkContent
            icon={<SitemapIcon size={30} variant="solid" />}
            title={t('navigation.company.team.title')}
            description={t('navigation.company.team.description')}
          />
        </LocalizedLink>
        <LocalizedLink to={CONTACT_PAGE}>
          <LinkContent
            icon={<MailIcon size={30} />}
            title={t('navigation.company.contact.title')}
            description={t('navigation.company.contact.description')}
          />
        </LocalizedLink>
        <LocalizedLink to={REVIEWS_PAGE}>
          <LinkContent
            icon={<StarIcon size={30} />}
            title={t('navigation.company.reviews.title')}
            description={t('navigation.company.reviews.description')}
          />
        </LocalizedLink>
        <LocalizedLink to={CUSTOMER_REVIEWS_PAGE}>
          <LinkContent
            icon={<ProjectIcon size={30} />}
            title={t('navigation.company.customerReviews.title')}
            description={t('navigation.company.customerReviews.description')}
          />
        </LocalizedLink>
      </div>
      <div className={classes.content} style={{ backgroundColor: theme.colors.gray[2] }}>
        <LocalizedLink to={GDPR_PAGE}>
          <LinkContent
            icon={<ShieldCheckIcon size={30} />}
            title={t('navigation.gdpr.title')}
            description={t('navigation.gdpr.description')}
          />
        </LocalizedLink>
        <LocalizedLink to={TAC_PAGE}>
          <LinkContent
            icon={<ListIcon size={30} />}
            title={t('navigation.termsAndConditions.title')}
            description={t('navigation.termsAndConditions.description')}
          />
        </LocalizedLink>
      </div>
    </>
  );
}

function QuestionBanks() {
  const { t } = usePublicTranslation();
  const { classes } = useStyles();
  return (
    <div className={classes.content}>
      <LocalizedLink to={QUESTION_BANKS_EASA_PAGE}>
        <LinkContent
          icon={
            <div className={classes.icon}>
              <CountryFlagIcon variant="uk" size={30} />
            </div>
          }
          title={t('navigation.questionBanks.easa.title')}
          description={t('navigation.questionBanks.easa.description')}
        />
      </LocalizedLink>
      <LocalizedLink to={QUESTION_BANKS_EASA_GERMAN_PAGE}>
        <LinkContent
          icon={
            <div className={classes.icon}>
              <CountryFlagIcon variant="de" size={30} />
            </div>
          }
          title={t('navigation.questionBanks.easaGerman.title')}
          description={t('navigation.questionBanks.easaGerman.description')}
        />
      </LocalizedLink>
      <LocalizedLink to={QUESTION_BANKS_PPL_PAGE}>
        <LinkContent
          icon={<DaecIcon size={30} />}
          title={t('navigation.questionBanks.ppl.title')}
          description={t('navigation.questionBanks.ppl.description')}
        />
      </LocalizedLink>
    </div>
  );
}

export interface DropdownNavigationProps {
  offsetTop: number;
}

export function DropdownNavigation({ offsetTop }: DropdownNavigationProps) {
  const { t } = usePublicTranslation();
  const lng = useLocale();
  const { classes: optionClasses } = useDropdownOptionStyles();
  const { classes } = useStyles();
  return (
    <DropdownProvider key={lng}>
      <ul className={classes.list}>
        <DropdownOption name={t('navigation.company.title')} content={Company} />
        <DropdownOption name={t('navigation.solutions.title')} content={Solutions} />
        <DropdownOption name={t('navigation.products.title')} content={Products} />
        <DropdownOption name={t('navigation.questionBanks.title')} content={QuestionBanks} />
        <li>
          <LocalizedLink to={BLOG_PAGE}>
            <button type="button" className={optionClasses.option} style={{ cursor: 'pointer' }}>
              {t('navigation.blog.title')}
            </button>
          </LocalizedLink>
        </li>
        <li>
          <a href="/store">
            <button type="button" className={optionClasses.option} style={{ cursor: 'pointer' }}>
              {t('navigation.store.title')}
            </button>
          </a>
        </li>
      </ul>
      <DropdownRoot offsetTop={offsetTop} />
    </DropdownProvider>
  );
}
