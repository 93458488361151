import React from 'react';
import { AviProvider, Global, GlobalStyles, NormalizeCSS } from '@aviationexam/core';
import { Locale, LocaleProvider } from 'features/localization';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import useStyles from './Layout.styles';

interface LayoutProps {
  children: React.ReactNode;
  locale: Locale;
}

export function Layout({ children, locale }: LayoutProps) {
  const { classes, theme } = useStyles();
  return (
    <AviProvider
      theme={{
        colorScheme: 'light',
        headings: {
          sizes: {
            ...theme.headings.sizes,
            h2: { fontSize: 36 },
          },
        },
      }}
    >
      <GlobalStyles />
      <Global
        styles={() => ({
          body: {
            backgroundColor: '#FBFAFB',
          },
          '[type="button"]': {
            WebkitAppearance: 'none',
          },
        })}
      />
      <NormalizeCSS />
      <LocaleProvider locale={locale}>
        <div className={classes.root}>
          <header className={classes.header}>
            <Header />
          </header>
          <main className={classes.main}>{children}</main>
          <footer>
            <Footer />
          </footer>
        </div>
      </LocaleProvider>
    </AviProvider>
  );
}
