import { createStyles } from '@aviationexam/core';

export default createStyles((theme, _, getRef) => {
  const iconRef = getRef('icon');
  const titleRef = getRef('title');
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing.xs,
      paddingBottom: theme.spacing.xs,
      [`&:hover .${iconRef}, &:hover .${titleRef}`]: {
        color: theme.colors[theme.primaryColor][6],
      },
    },
    icon: {
      ref: iconRef,
      flex: 'none',
      transition: 'color 0.2s cubic-bezier(0.64, 0, 0.35, 1)',
      color: theme.colors.gray[8],
    },
    captions: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: theme.spacing.sm,
      paddingLeft: theme.spacing.sm,
    },
    title: {
      ref: titleRef,
      transition: 'color 0.2s cubic-bezier(0.64, 0, 0.35, 1)',
      color: theme.colors.gray[8],
      fontSize: theme.fontSizes.md,
      fontWeight: 600,
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        fontSize: theme.fontSizes.sm,
      },
    },
    description: {
      color: theme.colors.gray[6],
      fontSize: theme.fontSizes.sm,
      fontWeight: 300,
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        fontSize: theme.fontSizes.xs,
      },
    },
  };
});
