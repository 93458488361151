import * as React from 'react';
import { SVGProps } from 'react';

function SvgFailed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 50 50"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 0c-1.848 0-3.36 1.29-3.813 3H9C7.355 3 6 4.355 6 6v39c0 1.645 1.355 3 3 3h32c1.645 0 3-1.355 3-3V6c0-1.645-1.355-3-3-3H28.812C28.36 1.29 26.849 0 25 0Zm0 2c1.117 0 2 .883 2 2 0 .55.45 1 1 1h3v2H19V5h3c.55 0 1-.45 1-1 0-1.117.883-2 2-2ZM9 5h8v3c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V5h8c.566 0 1 .434 1 1v39c0 .566-.434 1-1 1H9c-.566 0-1-.434-1-1V6c0-.566.434-1 1-1Zm19.906 11.969c-.043.008-.086.02-.125.031a.993.993 0 0 0-.781.703c-.105.367.004.758.281 1.016L30.563 21l-2.282 2.281a1.014 1.014 0 0 0 0 1.438c.399.398 1.04.398 1.438 0L32 22.437l2.281 2.282c.399.398 1.04.398 1.438 0a1.014 1.014 0 0 0 0-1.438L33.437 21l2.282-2.281c.324-.301.41-.778.215-1.172A.996.996 0 0 0 34.875 17a1.008 1.008 0 0 0-.594.281L32 19.563l-2.281-2.282a1.004 1.004 0 0 0-.813-.312ZM13.72 20c-.551.078-.938.59-.86 1.14.079.551.59.938 1.141.86h10c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H13.719Zm15.187 9.969c-.043.008-.086.02-.125.031a.993.993 0 0 0-.781.703c-.105.367.004.758.281 1.016L30.563 34l-2.282 2.281a1.014 1.014 0 0 0 0 1.438c.399.398 1.04.398 1.438 0L32 35.437l2.281 2.282c.399.398 1.04.398 1.438 0a1.014 1.014 0 0 0 0-1.438L33.437 34l2.282-2.281c.324-.301.41-.778.215-1.172A.996.996 0 0 0 34.875 30a1.008 1.008 0 0 0-.594.281L32 32.563l-2.281-2.282a1.004 1.004 0 0 0-.813-.312ZM13.72 33c-.551.078-.938.59-.86 1.14.079.551.59.938 1.141.86h10c.36.004.695-.184.879-.496a1.01 1.01 0 0 0 0-1.008c-.184-.312-.52-.5-.879-.496H13.719Z"
        fill="#FF5236"
      />
    </svg>
  );
}

export default SvgFailed;
