import { createStyles, AviTheme, ACTION_ICON_SIZES } from '@aviationexam/core';

export interface HoverCardStyles {
  backgroundColor: string | ((theme: AviTheme) => string);
  color: string | ((theme: AviTheme) => string);
}

export default createStyles((theme, { backgroundColor, color }: HoverCardStyles, getRef) => {
  const hoverCardRef = getRef('hoverCard');
  const actionIconRef = getRef('actionIcon');
  return {
    card: {
      position: 'relative',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      backgroundColor: theme.white,
      boxShadow: theme.shadows.xl,
      padding: theme.spacing.lg,
      paddingBottom: theme.spacing.lg * 2 + ACTION_ICON_SIZES.lg,
      '&:hover, &:focus': {
        [`& .${hoverCardRef}`]: {
          top: 0,
        },
      },
    },
    hoverCard: {
      ref: hoverCardRef,
      position: 'absolute',
      width: '100%',
      height: '100%',
      inset: 0,
      top: '100%',
      backgroundColor:
        typeof backgroundColor === 'string' ? backgroundColor : backgroundColor(theme),
      color: typeof color === 'string' ? color : color(theme),
      padding: theme.spacing.lg,
      paddingBottom: theme.spacing.lg * 2 + ACTION_ICON_SIZES.lg,
      transition: 'top 0.3s ease-in-out',
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'center',
    },
    title: {
      fontSize: 24,
      fontWeight: 600,
    },
    description: {
      fontWeight: 300,
    },
    actionIcon: {
      ref: actionIconRef,
      position: 'absolute',
      bottom: theme.spacing.lg,
      right: theme.spacing.lg,
    },
    [`& .${hoverCardRef}`]: {
      transitionProperty: 'background, color',
      transitionDuration: '0.3s',
      transitionTimingFunction: 'ease-in-out',
    },
  };
});
