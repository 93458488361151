import * as React from 'react';
import { SVGProps } from 'react';

function SvgDefault(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 58 54"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={32.5} cy={28.5} r={25.5} fill="#06C" fillOpacity={0.15} />
      <path
        d="M3 0C1.4 0 0 1.4 0 3v30c0 1.6 1.4 3 3 3h19.8c.5.6 1.3 1 2.2 1 .9 0 1.7-.4 2.2-1H47c1.6 0 3-1.4 3-3V3c0-1.6-1.4-3-3-3H27c-.8 0-1.5.3-2 .8-.5-.5-1.2-.8-2-.8H3Zm0 2h20c.6 0 1 .4 1 1v31H3c-.6 0-1-.4-1-1V3c0-.6.4-1 1-1Zm24 0h20c.6 0 1 .4 1 1v30c0 .6-.4 1-1 1H26V3c0-.6.4-1 1-1ZM6 22c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H6Zm24 0c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H30ZM6 27c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H6Zm0-15c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H6Zm0-5c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H6Zm0 10c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H6Zm24 10c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H30Zm0-15c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H30Zm0-5c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H30Zm0 10c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1H30Z"
        fill="#06C"
      />
    </svg>
  );
}

export default SvgDefault;
